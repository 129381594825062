import { useEffect, useState, useRef } from 'react';
import { Slide } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { CloseIcon, SuccessIcon, WarningIcon } from 'components/Icons';
import Grid from '@material-ui/core/Grid';
import ClipsConnectSuccess from './clips-connect-success';


const useStyles = makeStyles((theme) => ({
  errorContainer: {
    height: 145,
    backgroundColor: theme.common.black,
    position: 'fixed',
    left: '25%',
    right: '25%',
    bottom: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    '&.offset': {
      left: 'calc(25% + 176px)',
      right: '25%',
    },
    [theme.breakpoints.down('md')]: {
      '&.offset': {
        left: 'calc(15% + 176px) !important',
      },
      left: '15% !important',
      right: '15% !important',
    },
    [theme.breakpoints.only('xs')]: {
      '&.offset': {
        left: '0 !important',
      },
      left: "0 !important",
      right: "0 !important",
    },
  },
  errorHeader: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    paddingBottom: 2,
  },
  errorMessage: {
    lineHeight: 1,
    textAlign: 'center',
    color: theme.common.white,
    fontSize: '0.9rem',
    padding: '0 10px',
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 10,
    width: 16,
    height: 16,
  },
  errorInnerContainer: {
    height: 'inherit',
  },
  successIcon: {
    width: 40,
    height: 40
  },
}));

const ErrorSlideout = (props) => {
  const { message, options, close } = props;

  const classes = useStyles();

  const alertRef = useRef(null);

  const [showError, setShowError] = useState(false);

  const delay = 300

  const onCloseClicked = () => {
    setShowError(false);
    setTimeout(() => {
      close();
    }, delay)
  }

  // react-alert doesn't let us disable enter/exit animations, so we slide up this error right after the
  // alert finishes its fade in animation, and slide it down right before it starts its fade out animation.
  useEffect(() => {
    setTimeout(() => {
      setShowError(true);
    }, delay)

    setTimeout(() => {
      setShowError(false);
    }, options.timeout - delay)

    // Handle clicks outside of the alert. If the user clicks outside of the alert, we want to close it.
    const handleClickOutside = (event) => {
      if (alertRef.current && !alertRef.current.contains(event.target)) {
        onCloseClicked();
      }
    }

    window.addEventListener('click', handleClickOutside);

    return () => {
      window.removeEventListener('click', handleClickOutside);
    }
  }, [])

  return (
    <Slide direction='up' in={showError} mountOnEnter unmountOnExit>
      <div 
        ref={alertRef}
        className={`${classes.errorContainer} ${options.offsetError ? 'offset' : ''}`}
        style={options.isClipsConnectSuccess ? { height: 200 } : null}
      >
        {options.isClipsConnectSuccess ? 
        <ClipsConnectSuccess /> :
        <>
          <CloseIcon className={classes.closeIcon} onClick={onCloseClicked} />
          <Grid
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.errorInnerContainer}
          >
            <Grid item>
              {options.type === 'success' ? (
                <SuccessIcon className={classes.successIcon} />
              ) : (
                <WarningIcon />
              )}
            </Grid>
            <Grid item>
              <div className={classes.errorHeader}>{options.title}</div>
              <div className={classes.errorMessage}>{message ?? options.body}</div>
            </Grid>
          </Grid>
        </>}
      </div>
    </Slide>
  );
};

export default ErrorSlideout;
