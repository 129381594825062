import { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import { ChainLinkIcon } from 'components/Icons';
import closeImg from 'assets/images/close.svg';
import successImg from 'assets/images/success.svg';
import linkBelowImg from 'assets/images/link-below.svg';
import pillarioLogo from 'assets/images/pillario-logo.png';
import { slideoutTransitionDelay } from 'config/ui';
import { useAlert } from 'react-alert';

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    cursor: 'pointer',
  },
  drawer: {
    overflow: 'hidden',
  },
  paper: {
    overflowX: 'hidden',
    height: '100%',
    '&.maxWidth': {
      minWidth: 450,
      maxWidth: 500,
    },
  },
  drawerContainer: {
    height: '100%',
    backgroundColor: theme.modalDarkBackground,
    '@media (max-height: 500px)': {
      height: 'unset',
    },
  },
  headerContainer: {
    flexGrow: 0.5,
  },
  headerImage: {
    width: 45,
  },
  headerTitle: {
    color: theme.common.white,
    fontSize: '1.3rem',
    textAlign: 'center',
    lineHeight: 1.3,
    fontFamily: 'Figtree-SemiBold',
  },
  closeIconHeader: {
    backgroundColor: theme.modalDarkBackground,
    padding: `${theme.spacing(1.5)}px ${theme.spacing(2)}px ${theme.spacing(1)}px`,
  },
  hr: {
    width: '90%',
    margin: `${theme.spacing(2)}px auto`,
    backgroundColor: theme.grey[800],
    height: 2,
    border: 0,
  },
  contentContainer: { flexGrow: 1 },
  contentInnerContainer: {
    height: '100%',
  },
  contentHeaderContainer: {
    flexGrow: 0.4,
  },
  contentHeader: {
    color: theme.common.white,
    fontSize: '1.5rem',
    textAlign: 'center',
    padding: `0 ${theme.spacing(2)}px`,
    lineHeight: 1.2,
    '&.spaceTop': {
      '@media (max-height: 780px)': {
        fontSize: '1.4rem',
      },
      '@media (max-height: 630px)': {
        fontSize: '1.25rem',
      },
    },
  },
  contentDescription: {
    color: theme.grey[400],
    fontSize: '1.15rem',
    textAlign: 'center',
  },
  pillarLogo: {
    marginTop: 30,
    padding: '0 !important',
    '& img': {
      objectFit: 'contain',
      height: 50,
    }
  },
  pillarLink: {
    marginTop: 10,
    padding: '0 !important',
    '& a': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      color: theme.blue,
      textDecoration: 'underline',
    }
  },
  footerContainer: {
    paddingBottom: theme.spacing(2.5),
    width: '90%',
    margin: 'auto',
  },
  footerInnerContainer: {
    padding: `${theme.spacing(1.4)}px ${theme.spacing(2)}px`,
    backgroundColor: theme.common.black,
    borderRadius: 5,
  },
  link: {
    marginTop: theme.spacing(0.8),
    color: theme.common.white,
    fontSize: '1.3rem',
  },
  chainlinkIcon: {
    cursor: 'pointer',
  },
  tag: {
    color: theme.blue,
  },
}));

const SaveProfileDrawer = ({ asqMeTag, mobileType, open, onClose, partner }) => {
  const classes = useStyles();

  const alert = useAlert();

  const { blue } = useTheme();

  return (
    <Drawer
      classes={{
        root: classes.drawer,
        paper: `${classes.paper} ${!mobileType ? 'maxWidth' : ''}`,
        paperAnchorTop: classes.paper,
      }}
      transitionDuration={400}
      anchor={mobileType ? 'top' : 'right'}
      open={open}
      onClose={onClose}
    >
      <Grid container direction='column' className={classes.drawerContainer}>
        {/* close icon */}
        <Grid
          item
          container
          justifyContent='flex-end'
          alignItems='center'
          className={classes.closeIconHeader}
        >
          <Grid item>
            <img className={classes.menuIcon} src={closeImg} onClick={onClose} />
          </Grid>
        </Grid>
        {/* header */}
        <Grid
          item
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          spacing={1}
          className={classes.headerContainer}
        >
          <Grid item>
            <img src={successImg} className={classes.headerImage} alt='success' />
          </Grid>
          <Grid item>
            <div className={classes.headerTitle}>
              Great Job! <br /> Your changes have been saved.
            </div>
          </Grid>
        </Grid>
        {/* hr */}
        <Grid item>
          <hr className={classes.hr} />
        </Grid>
        {/* content */}
        <Grid item container className={classes.contentContainer}>
          <Grid item xs />
          <Grid item xs={10}>
            <Grid
              container
              direction='column'
              alignItems='center'
              justifyContent='space-evenly'
              className={classes.contentInnerContainer}
            >
              <Grid
                item
                container
                direction='column'
                alignItems='center'
                className={classes.contentHeaderContainer}
                spacing={3}
              >
                {/* header */}
                <Grid item>
                  <div className={`${classes.contentHeader} ${!mobileType ? 'spaceTop' : ''}`}>
                    Time to start answering questions!
                  </div>
                </Grid>
                {/* description */}
                <Grid item>
                  <div className={classes.contentDescription}>
                    Copy your AsqMe link below and share it on all your social channels
                  </div>
                </Grid>
                {partner === 'pillar.io' && (
                  <>
                    <Grid item className={classes.pillarLogo}>
                      <img src={pillarioLogo} alt='pillario' />
                    </Grid>
                    <Grid item className={classes.pillarLink}>
                      <a href='https://pillar.io/' target='_blank' rel='noreferrer'>Go to Pillar</a>
                    </Grid>
                  </>
                )}
              </Grid>
              <Grid item>
                <img src={linkBelowImg} alt='link' />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs />
        </Grid>
        {/* footer */}
        <Grid item className={classes.footerContainer}>
          <Grid
            item
            container
            justifyContent='space-between'
            className={classes.footerInnerContainer}
          >
            <Grid item>
              <div className={classes.link}>
                AsqMe.com/<span className={classes.tag}>{asqMeTag || '@asqMeTag'}</span>
              </div>
            </Grid>
            <Grid item>
              <CopyToClipboard
                text={`${process.env.REACT_APP_WEB_BASE_URL}/${asqMeTag || ''}`}
                onCopy={() => {
                  alert.success(null, { title: "All set", body: "Link copied to clipboard.", timeout: 1000 + slideoutTransitionDelay, offsetError: true })
                }}
              >
                <ChainLinkIcon fillColor={blue} className={classes.chainlinkIcon} />
              </CopyToClipboard>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SaveProfileDrawer;
