import { useMemo, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, useStripe } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import BaseHeader from 'components/Header/BaseHeader';
import { WhiteLogoWhiteContentIcon } from 'components/Icons';
import loaderImg from 'assets/images/loader.gif';

const useStyles = makeStyles((theme) => ({
  proceedToCheckoutContainer: {
    height: '100%',
    backgroundColor: 'rgb(249,249,249)',
  },
  proceedToCheckoutInnerContainer: {
    height: '100%',
  },
  header: {
    backgroundColor: theme.blue,
    paddingBottom: 0,
  },
  headerLogosContainer: {
    backgroundColor: theme.blue,
    width: '100%',
    height: 70,
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  },
  logoPlaceholderIcon: {
    transform: 'translateY(50%)',
    borderRadius: 50,
    width: 70,
    height: 70,
    backgroundColor: theme.common.black,
    border: `2px solid ${theme.common.white}`,
    position: 'relative',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
  },
  tipProcessingContainer: {
    flexGrow: 1,
  },
  loader: { width: 80 },
  processHeader: { fontSize: '1.2rem', textAlign: 'center', fontFamily: 'Figtree-Bold' },
  processMessage: { fontSize: '1rem', textAlign: 'center' },
}));

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const PaymentIntentPendingProcess = ({ clientSecret, onSuccess, onError }) => {
  const classes = useStyles();
  const stripe = useStripe();

  useEffect(() => {
    if (!stripe || !clientSecret) return;

    // examine the transaction status
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case 'succeeded':
        case 'requires_capture':
          onSuccess();
          // onError();
          break;

        case 'processing': {
          console.log("Setup processing. We'll update you when setup is set.");
          break;
        }

        case 'requires_payment_method': {
          onError();
          break;
        }

        default: {
          onError();
          break;
        }
      }
    });
  }, [stripe]);

  return (
    <Grid container className={classes.proceedToCheckoutContainer}>
      <Grid item sm xs />
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.proceedToCheckoutInnerContainer}
        >
          <Grid container direction='column'>
            {/* header */}
            <BaseHeader
              className={classes.header}
              leftIcon={<WhiteLogoWhiteContentIcon />}
              hideRightIcon
            />
          </Grid>

          <Grid
            item
            container
            direction='column'
            alignItems='center'
            justifyContent='center'
            className={classes.tipProcessingContainer}
          >
            <Grid item>
              <img src={loaderImg} className={classes.loader} alt='' />
            </Grid>
            <Grid item className={classes.paymentSetupProcessing}>
              <div className={classes.processHeader}>Card processing</div>
              <div className={classes.processMessage}>Processing your payment information</div>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item sm xs />
    </Grid>
  );
};

const withStripeElements = (Component) => (props) => {
  const { clientSecret } = props;

  const stripeProps = useMemo(
    () =>
      clientSecret
        ? {
            options: {
              clientSecret,
              appearance: {
                theme: 'stripe',
              },
            },
            stripe: stripePromise,
          }
        : null,
    [clientSecret],
  );

  return stripeProps ? (
    <Elements {...stripeProps}>
      <Component {...props} />
    </Elements>
  ) : null;
};

export default withStripeElements(PaymentIntentPendingProcess);
