import { useState, useMemo, useEffect } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAlert } from 'react-alert';
import { Divider } from '@material-ui/core';
import { Grid, Dialog, InputBase } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import SettingsToggle from './SettingsToggle';
import { CloseIconAlt } from 'components/Icons';
import { updateThankBankSettings } from 'store/creator';
import { queryProfileTypes } from 'store/auth/profile';
import { defaultAlertError, thankBankPaymentMethods } from 'utils/common/constants';
import goldLogoImg from 'assets/images/gold-logo-black-content.svg';
import cardIcon from 'assets/images/icon-card.svg';
import buyMeACoffeeIcon from 'assets/images/icon-buy-me-a-coffee.png';
import cashAppIcon from 'assets/images/icon-cashapp.png';
import paypalIcon from 'assets/images/icon-paypal.png';
import venmoIcon from 'assets/images/icon-venmo.png';
import chevronRight from 'assets/images/chevron-right.svg';
import { slideoutTransitionDelay } from 'config/ui';

const useStyles = makeStyles((theme) => ({
  settingsContainer: {
    '& .MuiDivider-root': {
      backgroundColor: '#EEEEEE',
      height: 1,
      margin: '12px 0',
    },
  },
  thankBankInfo: {
    fontFamily: 'Figtree-Regular',
    fontSize: 16,
    lineHeight: '19.2px',
    marginBottom: 5,
  },
  superscript: {
    fontSize: '0.55rem',
    verticalAlign: '0.3rem',
    lineHeight: 0,
  },
  tippingServicesContainer: {
    border: '1px solid #ffd058',
    borderRadius: 4,
    borderLeftWidth: 5,
    padding: 16,
    position: 'relative',
    backgroundColor: 'white',
    marginTop: 20,
  },
  tippingServicesHeader: {
    gap: 10,
    marginBottom: 20,
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1rem',
      color: '#666666',
    }
  },
  tipOptionsHeader: {
    fontFamily: 'Figtree-Bold',
    fontWeight: 700,
    size: '0.75rem',
    lineHeight: '0.9rem',
    color: '#666666',
    marginBottom: 10,
    '& span:nth-child(2)': {
      fontFamily: 'Figtree-Regular',
      color: 'black'
    }
  },
  tipOptionsContainer: {
    gap: 10,
  },
  tipOptionsMargin: {
    marginBottom: 30,
  },
  tipOptionButton: {
    display: 'flex',
    gap: 10,
    justifyContent: 'start',
    height: 50,
    padding: '10px 20px',
    backgroundColor: '#EEEEEE',
    '& .MuiButton-label': {
      height: '100%',
      display: 'flex',
      gap: 10,
      justifyContent: 'start',
    },
    '& .MuiButton-label img': {
      maxHeight: '100%',
      objectFit: 'contain',
      width: 26,
    },
    '& .MuiButton-label span': {
      textTransform: 'none',
      fontFamily: 'Figtree-Bold',
      fontSize: '1rem',
      lineHeight: '1.2rem',
    },
  },
  chevronIcon: {
    width: 18,
    height: 18,
  },
  flexSpacer: {
    flex: 1,
  },
  dialog: {
    '& .MuiDialog-paper': {
      borderRadius: 20,
    }
  },
  dialogDisabled: {
    '& .MuiDialog-paper': {
      backgroundColor: '#FAFAFA'
    }
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    width: '100%',
    padding: 20,
    '& span': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1rem',
      color: '#666666'
    },
  },
  dialogHeaderContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    width: '100%',
    '& button': {
      minWidth: 'auto',
      minHeight: 'auto',
      padding: 0,
      alignItems: 'start',
    },
  },
  linkTypeText: {
    color: 'black !important',
    marginTop: 10,
  },
  linkTypeTextMargin: {
    marginBottom: 40,
  },
  linkInputGroup: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    margin: '40px 0',
    width: '100%',
  },
  linkInputLabel: {
    fontFamily: 'Figtree-Bold',
    fontSize: '1rem',
    color: '#666666',
    marginBottom: 6,
  },
  linkInput: {
    width: '100%',
    height: 42,
    fontFamily: 'Figtree-Regular',
    fontSize: '1rem',
    padding: '10px 20px',
    borderRadius: 3,
    backgroundColor: '#EEEEEE',
    '& input': {
      textAlign: 'start',
      color: '#999999',
    }
  },
  linkInputDisabled: {
    backgroundColor: '#FAFAFA',
    paddingLeft: 0,
  },
  linkButton: {
    width: '100%',
    height: 44,
    backgroundColor: theme.blue,
    '& .MuiButton-label': {
      fontFamily: 'Figtree-Bold',
      fontSize: '1rem',
      lineHeight: '1.2rem',
      color: 'white',
      textTransform: 'none',
    }
  },
  unLinkButton: {
    backgroundColor: '#D44444',
  }
}));

const ThankBankSettings = (props) => {
  const { user, showPremiumFeaturesDrawer } = props;

  const alert = useAlert();
  const queryClient = useQueryClient();

  const classes = useStyles();

  const [tipJarEnabled, setTipJarEnabled] = useState(user.tip_jar_enabled);

  useEffect(() => {
    setTipJarEnabled(user.tip_jar_enabled)
  }, [user])

  const { 
    mutate: onUpdateThankBankSettings, 
  } = useMutation({
    mutationFn: updateThankBankSettings,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
      alert.success(null, { title: "All set", body: "ThankBank settings updated successfully.", timeout: 1000 + slideoutTransitionDelay, offsetError: true })
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const tipSettignsChanged = (tip_jar_enabled, service, value) => {
    let settings = {
      tip_jar_enabled: user.tip_jar_enabled,
      card_tip_enabled: user.card_tip_enabled,
      cash_app_tip_url: user.cash_app_tip_url,
      paypal_tip_url: user.paypal_tip_url,
      venmo_tip_url: user.venmo_tip_url,
      buy_me_a_coffee_tip_url: user.buy_me_a_coffee_tip_url,
    }

    if (tip_jar_enabled != null) {
      settings.tip_jar_enabled = tip_jar_enabled;
    }

    if (service === thankBankPaymentMethods.card) {
      settings.card_tip_enabled = value ?? false;
    } else if (service === thankBankPaymentMethods.cashApp) {
      settings.cash_app_tip_url = value;
    } else if (service === thankBankPaymentMethods.payPal) {
      settings.paypal_tip_url = value;
    } else if (service === thankBankPaymentMethods.venmo) {
      settings.venmo_tip_url = value;
    } else if (service === thankBankPaymentMethods.buyMeACoffee) {
      settings.buy_me_a_coffee_tip_url = value;
    }

    onUpdateThankBankSettings(settings);
  }

  return (
    <Grid container className={classes.settingsContainer}>
      <Grid item xs={12}>
        <p className={classes.thankBankInfo}>ThankBank is where your audience shows their appreciation.</p>
      </Grid>
      <Grid item xs={12}>
        <Divider /> 
      </Grid>
      <SettingsToggle
        text={'Get Star Ratings'}
        checked={true}
        disabled={true}
      />
      <Grid item xs={12}>
        <Divider /> 
      </Grid>
      <SettingsToggle
        text={'Collect Thank You Notes'}
        checked={true}
        disabled={true}
      />
      <Grid item xs={12}>
        <Divider /> 
      </Grid>
      <SettingsToggle
        text={'Enable Tips'}
        checked={tipJarEnabled}
        onChange={(e) => {
          let isEnabled = e.target.checked;
          setTipJarEnabled(isEnabled)
          tipSettignsChanged(isEnabled)
        }}
      />
      <TippingServicesSettings 
        user={user} 
        onTipSettingsChanged={tipSettignsChanged}
        showPremiumFeaturesDrawer={showPremiumFeaturesDrawer}
      />
    </Grid>
  )
}

const TippingServicesSettings = (props) => {
  const { user, onTipSettingsChanged, showPremiumFeaturesDrawer } = props;

  const classes = useStyles();

  const [selectedAccountType, setSelectedAccountType] = useState(null)
  const [showLinkAccountDialog, setShowLinkAccountDialog] = useState(false);

  const tipAccountTypes = {
    card: {
      service: thankBankPaymentMethods.card,
      icon: cardIcon,
      title: 'Credit/Debit Card',
      isFlag: true, // This means this account type can either be active or not - there is no URL to link
    },
    cashApp: {
      service: thankBankPaymentMethods.cashApp,
      icon: cashAppIcon,
      title: 'Cash App',
      urlPlaceholder: 'https://www.cash.app/...',
    },
    payPal: {
      service: thankBankPaymentMethods.payPal,
      icon: paypalIcon,
      title: 'PayPal',
      urlPlaceholder: 'https://www.paypal.com/...',
    },
    venmo: {
      service: thankBankPaymentMethods.venmo,
      icon: venmoIcon,
      title: 'Venmo',
      urlPlaceholder: 'https://www.venmo.com/...',
    },
    buyMeACoffee: {
      service: thankBankPaymentMethods.buyMeACoffee,
      icon: buyMeACoffeeIcon,
      title: 'Buy Me a Coffee',
      urlPlaceholder: 'https://www.buymeacoffee.com/...',
    },
  };

  const { activeTipOptions, availableTipOptions } = useMemo(() => {
    let activeOptions = [];
    let availableOptions = [];

    if (user.card_tip_enabled) {
      activeOptions.push({ ...tipAccountTypes.card, isActive: true })
    } else {
      availableOptions.push(tipAccountTypes.card)
    }

    if (user.cash_app_tip_url) {
      activeOptions.push({ ...tipAccountTypes.cashApp, url: user.cash_app_tip_url })
    } else {
      availableOptions.push(tipAccountTypes.cashApp)
    }

    if (user.paypal_tip_url) {
      activeOptions.push({ ...tipAccountTypes.payPal, url: user.paypal_tip_url })
    } else {
      availableOptions.push(tipAccountTypes.payPal)
    }

    if (user.venmo_tip_url) {
      activeOptions.push({ ...tipAccountTypes.venmo, url: user.venmo_tip_url })
    } else {
      availableOptions.push(tipAccountTypes.venmo)
    }

    if (user.buy_me_a_coffee_tip_url) {
      activeOptions.push({ ...tipAccountTypes.buyMeACoffee, url: user.buy_me_a_coffee_tip_url })
    } else {
      availableOptions.push(tipAccountTypes.buyMeACoffee)
    }

    return {
      activeTipOptions: activeOptions,
      availableTipOptions: availableOptions,
    }
  }, [user])

  const onTipOptionClick = (accountType) => {
    if (user.is_subscribed) {
      setSelectedAccountType(accountType)
      setShowLinkAccountDialog(true)
    } else {
      showPremiumFeaturesDrawer()
    }
  }

  return (
    <Grid item xs={12} container className={classes.tippingServicesContainer}>
      <Grid item xs={12} container gap={10} className={classes.tippingServicesHeader}>
        <img src={goldLogoImg} alt='Gold Logo' />
        <span>Tipping Services</span>
      </Grid>
      <Grid item xs={12} container justifyContent='space-between' className={classes.tipOptionsHeader}>
        <span>Active</span>
        <span>{activeTipOptions.length} of 4</span>
      </Grid>
      <Grid item xs={12} container direction='column' className={`${classes.tipOptionsContainer} ${classes.tipOptionsMargin}`}>
        {activeTipOptions.map((option, index) => (
          <TipOptionButton
            key={`active-option-${index}`}
            icon={option.icon} 
            label={option.title}
            onClick={() => onTipOptionClick(option)}
          />
        ))}
      </Grid>
      <Grid item xs={12} container className={classes.tipOptionsHeader}>
        <span>Available</span>
      </Grid>
      <Grid item xs={12} container direction='column' className={classes.tipOptionsContainer}>
        {availableTipOptions.map((option, index) => (
          <TipOptionButton 
            key={`available-option-${index}`}
            icon={option.icon} 
            label={option.title}
            onClick={() => onTipOptionClick(option)}
            disabled={activeTipOptions.length === 4}
          />
        ))}
      </Grid>
      <LinkAccountDialog 
        open={showLinkAccountDialog}
        accountType={selectedAccountType}
        handleClose={() => setShowLinkAccountDialog(false)}
        onClick={() => setShowLinkAccountDialog(true)}
        onAccountAdded={(service, url) => {
          onTipSettingsChanged(null, service, url)
          setShowLinkAccountDialog(false)
        }}
        onAccountRemoved={(service) => {
          onTipSettingsChanged(null, service, null)
          setShowLinkAccountDialog(false)
        }}
      />
    </Grid>
  )
}

const TipOptionButton = (props) => {
  const { icon, label, ...otherProps } = props;

  const classes = useStyles();

  return (
    <Button
      className={classes.tipOptionButton}
      {...otherProps}
    >
      <img src={icon} alt={label} />
      <span>{label}</span>
      <div className={classes.flexSpacer}></div>
      <img src={chevronRight} alt='Chevron Right' className={classes.chevronIcon} />
    </Button>
  )
}

const LinkAccountDialog = (props) => {
  const { accountType, open, handleClose, onAccountAdded, onAccountRemoved } = props;

  const [linkUrl, setLinkUrl] = useState('')

  const classes = useStyles();

  useEffect(() => {
    setLinkUrl('')
  }, [open])

  return (
    <Dialog 
    open={open} 
    onClose={handleClose} 
    className={`${classes.dialog} ${accountType?.url ? classes.dialogDisabled : ''}`}
    fullWidth={true}
    >
      <div className={classes.dialogContainer}>
        <div className={classes.dialogHeaderContainer}>
          <Button onClick={handleClose}><CloseIconAlt /></Button>
        </div>
        <span>Account Type</span>
        <span className={`${classes.linkTypeText} ${accountType?.isFlag ? classes.linkTypeTextMargin : ''}`}>{accountType?.title}</span>
        {!accountType?.isFlag && <div className={classes.linkInputGroup}>
          <label htmlFor="linkInput" className={classes.linkInputLabel}>Url</label>
          <InputBase 
            id="linkInput"
            className={`${classes.linkInput} ${accountType?.url ? classes.linkInputDisabled : ''}`}
            placeholder={accountType?.urlPlaceholder}
            readOnly={accountType?.url != null && accountType?.url !== ''}
            value={accountType?.url || linkUrl}
            onChange={(e) => {
              setLinkUrl(e.target.value)
            }}
          />
        </div>}
        <Button
          className={`${classes.linkButton} ${accountType?.url || accountType?.isActive ? classes.unLinkButton : ''}`}
          onClick={() => {
            if (accountType?.url || accountType?.isActive) {
              onAccountRemoved(accountType?.service)
            } else {
              onAccountAdded(accountType?.service, accountType?.isFlag ? true : linkUrl)
            }
          }}
        >
          {accountType?.isFlag 
            ? accountType?.isActive ? "Deactivate " : "Activate"
            : accountType?.url ? "Unlink Account" : "Link Account"
          }
        </Button>
      </div>
    </Dialog>
  )
}

export default ThankBankSettings;