import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { useMutation, useQuery } from '@tanstack/react-query';
import ProfilePreview from 'components/ProfilePreview';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import SubmitSuccess from 'components/SubmitSuccess';
import QuestionPreview from './QuestionPreview';
import {
  queryCustomerTypes,
  generateQuestionCode,
  fetchTempUnansweredQuestion,
  retrieveTempQuestionCustomerData,
  sendQuestion,
  detectQuestionOriginLanguage,
} from 'store/customer';
import PaymentIntentPendingProcess from './PaymentIntentPendingProcess';
import { defaultAlertError, tipsValue, zeroValue } from 'utils/common/constants';
import useAppData from 'hooks/context';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: theme.common.white,
    color: theme.common.black,
  },
}));

const QuestionStep = ({ creatorData, tag }) => {
  const alert = useAlert();
  const classes = useStyles();

  const [searchParams, setSearchParams] = useSearchParams();
  const searchPaymentIntentClentSecret = searchParams.get('payment_intent_client_secret');
  const searchQuestionTrackingCode = searchParams.get('question_tracking_code');

  const tempUnfinishedQtc = searchParams.get('qtc');

  const [completedStatus, setCompletedStatus] = useState(null);

  const [questionTrackingCode, setQuestionTrackingCode] = useState('');
  const [customerData, setCustomerData] = useState({
    customer_message: '',
    customer_name: '',
    customer_email: '',
    customer_attachment_image_url: '',
    customer_attachment_image_encoded: '',
    customer_attachment_video_url: '',
    customer_attachment_video_encoded: '',
    customer_currency: 'USD', // This is for V1 where we charge all customers in USD
  });

  // if any of 2 required search params is missing, reset process (malicious attempt)
  useEffect(() => {
    if (
      (searchPaymentIntentClentSecret && !searchQuestionTrackingCode) ||
      (!searchPaymentIntentClentSecret && searchQuestionTrackingCode)
    )
      setSearchParams(undefined);
  }, []);

  // indentifier that detects if amount($) is applied for question price or not
  const isAmountSelectedForQuestionPrice = useMemo(() => {
    const isZeroCharge = creatorData.price_per_question === zeroValue;
    const chargeByTips = creatorData.price_per_question === tipsValue;

    return !isZeroCharge && !chargeByTips;
  }, [creatorData]);

  const isTipSelectedForQuestionPrice = useMemo(() => {
    return creatorData.price_per_question === tipsValue;
  }, [creatorData]);

  // After stripe redirect, if payment process is still pending and fails for some reason:
  // 1. fetch temporary question data and store them to state
  // 2. navigate customer to stripe form
  // 3. inform customer with error message
  // * If api call fails, reset process
  const {
    mutate: onRetrieveTempQuestionCustomerData,
    data: retrievedTempQuestionData,
    reset: clearRetrievedTempQustionData,
  } = useMutation({
    mutationFn: retrieveTempQuestionCustomerData,
    onSuccess: (data) => {
      setCustomerData((prev) => ({ ...prev, ...data }));
      setQuestionTrackingCode(searchQuestionTrackingCode);
      setTimeout(() => setSearchParams(undefined));
    },
    onError: () => {
      alert.error(null, defaultAlertError);
      setSearchParams(undefined);
    },
  });

  // Step 2b: If question successfully saved to api, display success screen & trigger track call
  const { mutate: onSendStandardQuestion, isLoading: sendStandardQuestionIsLoading } = useMutation({
    mutationFn: sendQuestion,
    onSuccess: () => {
      setCompletedStatus({
        mode: creatorData.price_per_question === zeroValue ? zeroValue : tipsValue,
      }); //  0/T
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  // Step 2a: (Applicable for standard or $0/Tip question submissions)
  // Send questionData to api
  // Update 1: Include generated question tracking code to body, in order to be deleted
  const submitStandardQuestion = useCallback(
    (data) => {
      const {
        customer_attachment_image_encoded,
        customer_attachment_video_encoded,
        customer_attachment_image_url,
        customer_attachment_video_url,
        ...customerDataToSend
      } = data || customerData;

      onSendStandardQuestion({
        ...customerDataToSend,
        customer_attachment_image_url: customer_attachment_image_url || undefined,
        customer_attachment_video_url: customer_attachment_video_url || undefined,
        creator_asqMe_tag: tag,
        question_tracking_code: questionTrackingCode || undefined,
      });
    },
    [customerData, questionTrackingCode],
  );

  // Step 1b: Upon question_tracking_code is returned from api, save it to state
  const {
    mutate: onGenerateQuestionCode,
    reset: clearGeneratedQuestionCode,
    data: generatedQuestionCode,
    isLoading: generateQuestionCodeIsLoading,
  } = useMutation({
    mutationFn: generateQuestionCode,
    onSuccess: (data) => {
      setQuestionTrackingCode(data?.question_tracking_code || data);
      setSearchParams(undefined);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError)
    },
  });

  // Translation step
  const {
    mutate: onDetectQuestionOriginLanguage,
    reset: clearDetectedQuestionOriginLanguage,
    data: detectedQuestionOriginLanguage,
    isLoading: detectedQuestionOriginLanguageIsLoading,
  } = useMutation({
    mutationFn: detectQuestionOriginLanguage,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  // Step 1a: Save customerData to state - Send customerData to api
  // Update 1: If question tracking code exists, include it to body (edit case)
  const saveCustomerData = useCallback(
    (data) => {
      // for $0/Tip questions, proceed to instant question submission
      if (!isAmountSelectedForQuestionPrice) {
        submitStandardQuestion(data);
        return;
      }

      setCustomerData({ ...data });

      // send the customer's question for possible missmatch detection
      onDetectQuestionOriginLanguage({ tag, message: data.customer_message });


      const {
        customer_attachment_image_encoded,
        customer_attachment_video_encoded,
        customer_attachment_image_url,
        customer_attachment_video_url,
        ...customerDataToSend
      } = data;

      onGenerateQuestionCode({
        ...customerDataToSend,
        customer_attachment_image_url: customer_attachment_image_url || undefined,
        customer_attachment_video_url: customer_attachment_video_url || undefined,
        creator_asqMe_tag: tag,
        question_tracking_code: questionTrackingCode || data.question_tracking_code || undefined,
      });
    },
    [submitStandardQuestion],
  );

  // if a temporary abandoned question tracking code is found as search param,
  // fetch and populate temporarily stored customer data from that qtc
  useQuery({
    queryKey: [queryCustomerTypes.tempUnansweredQuestion, tempUnfinishedQtc],
    queryFn: fetchTempUnansweredQuestion,
    enabled: !!tempUnfinishedQtc,
    onSuccess: (data) => {
      if (!data.customer_email) setSearchParams(undefined);
      else {
        const attachments = {
          customer_attachment_image_encoded: data.customer_attachment_image_url || '',
          customer_attachment_image_url: data.customer_attachment_image_url
            ? data.customer_attachment_image_url.split(`${process.env.REACT_APP_S3_BUCKET_URL}/`)[1]
            : '',
          customer_attachment_video_encoded: data.customer_attachment_video_url || '',
          customer_attachment_video_url: data.customer_attachment_video_url
            ? data.customer_attachment_video_url.split(`${process.env.REACT_APP_S3_BUCKET_URL}/`)[1]
            : '',
        };

        const customerData = { ...data, question_tracking_code: tempUnfinishedQtc, ...attachments };
        saveCustomerData(customerData);
      }
    },
    onError: () => {
      setSearchParams(undefined);
    },
  });

  const clearServiceData = useCallback(() => {
    clearRetrievedTempQustionData();
    clearGeneratedQuestionCode();
    clearDetectedQuestionOriginLanguage();
  }, []);

  const translationConditionPassed = !!detectedQuestionOriginLanguage;

  return tempUnfinishedQtc ? (
    <Backdrop open className={classes.backdrop}>
      <CircularProgress color='inherit' thickness={3} size={70} />
    </Backdrop>
  ) : !!completedStatus ? (
    <SubmitSuccess
      isQuestionSubmitted
      questionMode={completedStatus?.mode}
      creatorData={creatorData}
    />
  ) : !!searchPaymentIntentClentSecret ? (
    <PaymentIntentPendingProcess
      clientSecret={searchPaymentIntentClentSecret}
      onSuccess={() => setCompletedStatus({ mode: 'P' })}
      onError={() =>
        onRetrieveTempQuestionCustomerData({ questionTrackingCode: searchQuestionTrackingCode })
      }
    />
  ) : (!!generatedQuestionCode && translationConditionPassed) || !!retrievedTempQuestionData ? (
    <QuestionPreview
      creatorData={creatorData}
      customerData={customerData}
      questionTrackingCode={questionTrackingCode}
      amountOptionEnabled={isAmountSelectedForQuestionPrice}
      forcePreviousTemporaryState={!!retrievedTempQuestionData}
      detectedQuestionOriginLanguage={detectedQuestionOriginLanguage}
      saveIsLoading={sendStandardQuestionIsLoading}
      onSubmitStandardQuestion={submitStandardQuestion}
      onStepBack={clearServiceData}
    />
  ) : creatorData ? (
    <ProfilePreview
      amountOptionEnabled={isAmountSelectedForQuestionPrice}
      tipOptionEnabled={isTipSelectedForQuestionPrice}
      creatorData={creatorData}
      customerData={customerData}
      onSave={saveCustomerData}
      saveIsLoading={
        sendStandardQuestionIsLoading ||
        generateQuestionCodeIsLoading ||
        detectedQuestionOriginLanguageIsLoading
      }
    />
  ) : null;
};

export default QuestionStep;
