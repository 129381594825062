import { useState, useMemo, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import SubscriptionBox from './SubscriptionBox';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Switch from '@material-ui/core/Switch';
import { ButtonBase, Divider } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { Dropdown } from 'antd';
import GridWrapper from 'components/GridWrapper';
import { AppAuthHeader } from 'components/Header';
import PremiumFeaturesDrawer from 'components/PremiumFeaturesDrawer';
import FeatureInfoDrawer from 'components/FeatureInfoDrawer';
import UnderDevFeatureDrawer from 'components/UnderDevFeatureDrawer';
import LinkInfoDrawer from './LinkInfoDrawer';
import { EditPencilIcon } from 'components/Icons';
import Header from './Header';
import Card from './Card';
import useAuth from 'hooks/auth';
import { resetPassword } from 'store/auth/okta';
import { updateWeeklyDashboardEmailSetting, getCustomerExportCsv } from 'store/creator';
import { queryProfileTypes } from 'store/auth/profile';
import { updateMessenger } from 'utils/intercomConfig';
import { languagesLookup } from 'utils/lookups/supportedTranslationLanguages';
import { useTipJar } from './utils';
import useAppData from 'hooks/context';
import { defaultAlertError, originTypes } from 'utils/common/constants';
import infoImg from 'assets/images/info-white.svg';
import blueLogoImg from 'assets/images/blue-logo-black-content.svg';
import settingsImg from 'assets/images/settings.svg';
import chainlinkWhiteImg from 'assets/images/chainlink-white.svg';
import chainlinkBlueImg from 'assets/images/chainlink-blue.svg';
import goldLogoImg from 'assets/images/gold-logo-black-content.svg';
import whiteLogoImg from 'assets/images/white-logo-white-content.svg';
import thankBankLogo from 'assets/images/thank-bank-logo.svg';
import qnaImg from 'assets/images/qna.svg';
import stripeImg from 'assets/images/stripe.svg';
import googleImg from 'assets/images/google-black.svg';
import { styles } from './styles';
import ThankBankSettings from './ThankBankSettings';
import ThankBankPreview from './ThankBankPreview';
import { slideoutTransitionDelay } from 'config/ui';
import ClipsElement from 'components/Clips/ClipsElement';
import copilotImg from 'assets/images/copilot-nav.svg';
import exportIcon from 'assets/images/customer-export-icon.svg';
import SettingsToggle from './SettingsToggle';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';

const inboxEnabled = process.env.REACT_APP_ENABLE_INBOX === 'true';

const CopyToClipboardWrapper = ({ children, is_subscribed_and_profile_completed, ...rest }) =>
  is_subscribed_and_profile_completed ? (
    <CopyToClipboard {...rest}>{children}</CopyToClipboard>
  ) : (
    children
  );

const useStyles = makeStyles(styles);

const Account = () => {
  const classes = useStyles();
  const alert = useAlert();
  const { breakpoints, modalDarkBackground } = useTheme();
  const mobileView = useMediaQuery(breakpoints.down('xs'));
  const smallView = useMediaQuery(breakpoints.down('sm'));

  const queryClient = useQueryClient();
  const { user } = useAppData();

  const [linkInfoDrawer, setLinkInfoDrawer] = useState(false);
  const [premiumFeaturesDrawer, setPremiumFeaturesDrawer] = useState(false);
  const [featureInfoDrawer, setFeatureInfoDrawer] = useState(null);
  const [showThankBankPreview, setShowThankBankPreview] = useState(false);
  const [underDevFeatureDrawer, setUnderDevFeatureDrawer] = useState(false);

  const [weeklyDashboardEmailEnabled, setWeeklyDashboardEmailEnabled] = useState(user.weekly_dashboard_email_enabled);

  useEffect(() => {
    setWeeklyDashboardEmailEnabled(user.weekly_dashboard_email_enabled)
  }, [user])

  const {
    mutate: onDownloadContacts,
  } = useMutation({
    mutationFn: getCustomerExportCsv,
    onSuccess: (res) => {
      if (res.data.size === 0) {
        const isMostRecent = res.request.responseURL.includes('mostRecent=true');

        const alertBody = () => {
          return (
            <p 
              className={classes.contactDownloadAlert}
            >
              {isMostRecent ? (
                'Share your AsqMe link to get more questions. '
              ) : (
                'Share your AsqMe link to start getting questions. '
              )}
              <a
                href="https://help.asqme.com/en/collections/7983665-promoting-your-asqme-link" 
                target='_blank' 
                rel="noreferrer"              
              >
                Learn more.
              </a>
            </p>
          )
        }

        alert.error(null,{ 
          title: isMostRecent ? 'No new contacts to download' : 'No contacts to download yet', 
          body: alertBody(), 
          timeout: 0, 
          offsetError: true 
        });

        return;
      }

      const contentDisposition = res.headers['content-disposition'];
      const filename = contentDisposition
        ? contentDisposition.split('filename=')[1].replace(/"/g, '')
        : 'contacts_export.csv';

      const href = URL.createObjectURL(res.data);

      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
      onError: (err) => {
        console.log('Error downloading contacts')
      }
  });

  const { 
    mutate: onUpdateWeeklyDashboardEmailSetting, 
  } = useMutation({
    mutationFn: updateWeeklyDashboardEmailSetting,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [queryProfileTypes.profile] });
      alert.success(null, { title: "All set", body: "Weekly dashboard email setting updated successfully.", timeout: 1000 + slideoutTransitionDelay, offsetError: true })
    },
    onError: (err) => {
      alert.error(null, {...defaultAlertError, offsetError: true });
    },
  });

  const drawerOpen = useMemo(
    () => linkInfoDrawer || underDevFeatureDrawer || !!featureInfoDrawer || premiumFeaturesDrawer,
    [linkInfoDrawer, underDevFeatureDrawer, featureInfoDrawer, premiumFeaturesDrawer],
  );

  const { mutate: onResetPassword, isLoading: resetPasswordIsLoading } = useMutation({
    mutationFn: resetPassword,
    onError: (error) => {
      alert.error(null, { ...defaultAlertError, offsetError: true });
    },
  });

  // hide intercom launcher when a drawer is open
  useEffect(() => {
    if (drawerOpen) updateMessenger({ hide_default_launcher: true });
    else updateMessenger({ hide_default_launcher: false });
  }, [drawerOpen]);

  const {
    asqMe_tag,
    email,
    is_subscribed,
    is_subscribed_and_profile_completed,
    tip_jar_enabled,
  } = useAuth();

  // tip jar feature
  const { isLoading: updateTipJarFeatureIsLoading, handleTipJarChange } = useTipJar({
    is_subscribed_and_profile_completed,
    setFeatureInfoDrawer,
    queryClient,
    alert,
  });

  const isLoading =
    updateTipJarFeatureIsLoading || resetPasswordIsLoading;

  return (
    <>
      <Grid container className={classes.container}>
        <GridWrapper>
          {isLoading && (
            <Backdrop open className={classes.backdrop}>
              <CircularProgress color='inherit' thickness={3} size={60} />
            </Backdrop>
          )}
          <Grid container direction='column' className={classes.innerContainer}>
            {/* header */}
            <AppAuthHeader />
            {/* page content */}
            <Grid item container direction='column'>
              {/* link section */}
              {!inboxEnabled && (
                <Grid item container direction='column'>
                  {/* link header */}
                  <Header
                    leftSection={{ icon: chainlinkWhiteImg, text: 'Your Links' }}
                    rightSection={
                      <img
                        onClick={() => setLinkInfoDrawer(true)}
                        src={infoImg}
                        className={classes.infoLink}
                        alt='info'
                      />
                    }
                  />
                  {/* 1st row */}
                  <Grid item container className={classes.firstSectionContainer}>
                    <Grid item sm xs />
                    <Grid item sm={12} xs={10}>
                      {/* qna catalog */}
                      <Card
                        isPremium
                        topLeftSection={{ icon: qnaImg, text: 'Q&A Catalog' }}
                        topRightSection={
                          <Grid container alignItems='center'>
                            <Grid item>
                              <img
                                className={classes.tipjarLinkInfo}
                                src={goldLogoImg}
                                alt='logo'
                                onClick={() => setUnderDevFeatureDrawer(true)}
                              />
                            </Grid>
                            <Grid item>
                              <Switch
                                classes={{
                                  root: classes.switchRoot,
                                  track: `${classes.switchTrack} disabled`,
                                  checked: classes.switchChecked,
                                  switchBase: `${classes.switchBase} disabled`,
                                }}
                                checked={false}
                                onChange={() => setUnderDevFeatureDrawer(true)}
                              />
                            </Grid>
                          </Grid>
                        }
                        bottomLeftSection={
                          <div className={`${classes.asqmeUrl} disabled`}>
                            AsqMe.com/ask/<span>{asqMe_tag || ''}</span>
                          </div>
                        }
                        bottomRightSection={
                          <CopyToClipboardWrapper
                            is_subscribed_and_profile_completed={false}
                            text={`${process.env.REACT_APP_WEB_BASE_URL}/ask/${asqMe_tag || ''}`}
                            onCopy={() => {
                              alert.success(null, {
                                title: 'All set',
                                body: 'Link copied to clipboard.',
                                timeout: 1000 + slideoutTransitionDelay,
                                offsetError: true,
                              });
                            }}
                          >
                            <img
                              src={chainlinkWhiteImg}
                              className={`${classes.chainIcon} disabled`}
                              alt='chain'
                            />
                          </CopyToClipboardWrapper>
                        }
                      />
                    </Grid>
                    <Grid item sm xs />
                  </Grid>
                </Grid>
              )}
              {/* First Draft section */}
              {process.env.REACT_APP_ENABLE_CLIPS === 'true' && (
                <Grid item container direction='column'>
                  <Header
                    leftSection={{ icon: copilotImg, text: 'FirstDraft' }}
                    extraClassName={classes.firstDraftHeader}
                  />
                  <Grid item container className={classes.firstDraftContainer}>
                    <Grid item sm xs />
                    <Grid item sm={12} xs={10}>
                      <ClipsElement showEnabledClips hideBoxShadow noPadding signUpButtonAlignment='flex-end' />
                    </Grid>
                    <Grid item sm xs />
                  </Grid>
                </Grid>
              )}
              {/* Download Contacts section */}
              <Grid item container direction='column' className={classes.sectionContainer}>
                <Header
                  leftSection={{ icon: exportIcon, text: 'Download Leads' }}
                />
                <Grid item container>
                  <Grid item sm xs />
                  <Grid item sm={12} xs={10}>
                    <Grid item className={classes.exportDescription}>
                      <span>Download the names, email addresses and questions of people who have contacted you through AsqMe.</span>
                    </Grid>
                    <Grid item container direction='row' justifyContent='flex-end' spacing={1}>
                      <Grid item className={classes.downloadButtonContainer}>
                        <ButtonBase
                          className={classes.outlinedButton}
                          onClick={() => {
                            trackAppCtaClick({
                              ...getPathAndPageName(),
                              cta_verbiage: 'Download All',
                            });
                            onDownloadContacts()
                          }}
                        >
                          Download All
                        </ButtonBase>
                      </Grid>
                      <Grid item className={classes.downloadButtonContainer}>
                        <ButtonBase
                          className={classes.filledButton}
                          onClick={() => {
                            trackAppCtaClick({
                              ...getPathAndPageName(),
                              cta_verbiage: 'Download New',
                            });
                            onDownloadContacts({ mostRecent: true })
                          }}
                        >
                          Download New
                        </ButtonBase>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sm xs />
                </Grid>
              </Grid>
              <Grid item container direction='column' className={classes.sectionContainer}>
                {/* settings header */}
                <Header leftSection={{ icon: settingsImg, text: 'Settings' }} />

                {/* settings content */}
                {/* 1st row */}
                <Grid item container className={classes.firstSectionContainer} spacing={2}>
                  <Grid item xs={12}>
                    <Grid container direction='column'>
                      {/* AsqMe Handle section */}
                      <Grid item container>
                        <Grid item sm xs />
                        <Grid item sm={12} xs={10}>
                          <Grid container direction='column'>
                            <Grid item className={classes.settingsTextContainer}>
                              <div>AsqMe Handle</div>
                            </Grid>
                            <Grid item container justifyContent='space-between' alignItems='center' spacing={2} className={classes.emailContainerInner}>
                              <Grid item>
                                <div className={classes.asqmeUrlContainer}>
                                  <div className={classes.asqmeUrl}>
                                    AsqMe.com/<span>{asqMe_tag || ''}</span>
                                  </div>
                                  <CopyToClipboard
                                    text={`${process.env.REACT_APP_WEB_BASE_URL}/${asqMe_tag || ''}`}
                                    onCopy={() => {
                                      alert.success(null, {
                                        title: 'All set',
                                        body: 'Link copied to clipboard.',
                                        timeout: 1000 + slideoutTransitionDelay,
                                        offsetError: true,
                                      });
                                    }}
                                  >
                                    <img
                                      src={chainlinkBlueImg}
                                      className={classes.chainIcon}
                                      alt='chain'
                                    />
                                  </CopyToClipboard>
                                </div>
                              </Grid>
                              <Grid item>
                                <Link to='/profile' className={classes.changeHandleLink}>
                                  Update
                                </Link>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid item sm xs />
                      </Grid>
                      {/* translation section */}
                      <Grid item container>
                        <Grid item sm xs />
                        <Grid item sm={12} xs={10}>
                          <Grid container direction='column'>
                            <Grid item className={classes.settingsTextContainer}>
                              <div>AutoTranslate</div>
                            </Grid>
                            <Grid item container justifyContent='space-between' alignItems='center' spacing={2} className={classes.emailContainerInner}>
                              <Grid item>
                                <div className={classes.settingsItemText}>{`My preferred language is ${languagesLookup.find(x => x.value === user.translationSettings?.preferred_language)?.label ?? 'English'}.`}</div>
                              </Grid>
                              <Grid item>
                                <Link to='/profile' className={classes.changeHandleLink}>
                                  Update
                                </Link>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid item sm xs />
                      </Grid>
                      {/* email section */}
                      <Grid item container>
                        <Grid item md xs />
                        <Grid item sm={12} xs={10}>
                          <Grid container direction='column'>
                            <Grid item className={classes.settingsTextContainer}>
                              <div>Email Address</div>
                            </Grid>
                            <Grid item container justifyContent='space-between' alignItems='center' spacing={2} className={classes.emailContainerInner}>
                              <Grid item>
                                <div className={classes.emailTextContainer}>
                                  {(user.last_origin || '').includes(originTypes.google) && (
                                    <img src={googleImg} alt='google' />
                                  )}
                                  <div className={classes.settingsItemText}>{email}</div>
                                </div>
                              </Grid>
                              <Grid item>
                                <Link to={`mailto:help@asqme.com?subject=I need to change the email address on my AsqMe account with handle ${user.asqMe_tag}`} target='_blank' className={classes.changeHandleLink}>
                                  Update
                                </Link>
                              </Grid>
                            </Grid>
                            <Divider />
                          </Grid>
                        </Grid>
                        <Grid item md xs />
                      </Grid>
                      {/* stripe connections section */}
                      {user.connected_account_enabled && (
                        <Grid container item>
                          <Grid item xs />
                          <Grid item sm={12} xs={10}>
                            <Grid
                              container
                              direction='column'
                            >
                              {/* header */}
                              <Grid item className={classes.settingsTextContainer}>
                                <div>Connections</div>
                              </Grid>
                              {/* language selection */}
                              <Grid item xs={12}>
                                <Grid
                                  container
                                  justifyContent='space-between'
                                  alignItems='center'
                                  className={classes.stripeSettingsContainer}
                                >
                                  <Grid item>
                                    <img className={classes.stripeImg} src={stripeImg} alt='stripe' />
                                  </Grid>
                                  <Grid item>
                                    <Link to='/stripe-login' target='_blank' className={classes.changeHandleLink}>
                                      Settings
                                    </Link>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Divider />
                            </Grid>
                          </Grid>
                          <Grid item xs />
                        </Grid>
                      )}
                      {/* Dashboard email settings */}
                      <Grid container item>
                        <Grid item xs />
                        <Grid item sm={12} xs={10}>
                          <Grid
                            container
                            direction='column'
                          >
                            <Grid item className={classes.settingsTextContainer}>
                              <div>Notifications</div>
                            </Grid>
                            <Grid item>
                              <SettingsToggle
                                text='Weekly Dashboard Email'
                                checked={weeklyDashboardEmailEnabled}
                                containerClass={classes.weeklyDashboardToggleContainer}
                                extraRootClass={classes.weeklyDashboardToggleRoot}
                                onChange={(e) => {
                                  let isEnabled = e.target.checked;
                                  setWeeklyDashboardEmailEnabled(isEnabled)
                                  onUpdateWeeklyDashboardEmailSetting({ weekly_dashboard_email_enabled: isEnabled })
                                }}
                              />
                            </Grid>
                            <Divider style={{ marginTop: 4 }}/>
                          </Grid>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              {/* Thank Bank */}
              <Grid item container direction='column' className={`${classes.sectionContainer} ${classes.thankBankContainer}`}>
                {/* Thank Bank header */}
                <Header
                  leftSection={{ icon: thankBankLogo, text: 'ThankBank', superscript: 'TM' }}
                  rightSection={
                    <Button
                      className={classes.thankBankPreviewButton}
                      onClick={() => setShowThankBankPreview(true)}
                    >
                      Preview
                    </Button>
                  }
                  rightAlignRightSection={true}
                />
                {/* Thank Bank content */}
                <Grid item container className={classes.firstSectionContainer}>
                  <Grid item sm xs />
                  <Grid item sm={12} xs={10}>
                    <ThankBankSettings
                      user={user}
                      showPremiumFeaturesDrawer={() => setPremiumFeaturesDrawer(true)}
                    />
                  </Grid>
                  <Grid item sm xs />
                </Grid>
              </Grid>
              {/* subscription section */}
              <Grid item container direction='column' className={classes.sectionContainer}>
                {/* subscription header */}
                <Header leftSection={{ icon: whiteLogoImg, text: 'Subscription Center' }} />
                {/* subscription content */}
                <Grid item container className={classes.firstSectionContainer}>
                  <Grid item sm xs />
                  <Grid item sm={12} xs={10}>
                    <SubscriptionBox />
                  </Grid>
                  <Grid item sm xs />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </GridWrapper>
      </Grid>
      {/* informational popups section */}
      <LinkInfoDrawer
        open={linkInfoDrawer}
        mobileType={mobileView}
        onClose={() => setLinkInfoDrawer(false)}
        onLinkSelect={() => {
          setPremiumFeaturesDrawer(true);
          setLinkInfoDrawer(false);
        }}
      />
      {/* Preview Thank Bank Drawer */}
      <ThankBankPreview
        user={user}
        open={showThankBankPreview}
        onClose={() => setShowThankBankPreview(false)}
      />
      <FeatureInfoDrawer
        open={!!featureInfoDrawer}
        mobileType={mobileView}
        type={featureInfoDrawer}
        onClose={() => setFeatureInfoDrawer(null)}
      />
      <PremiumFeaturesDrawer
        open={premiumFeaturesDrawer}
        mobileType={mobileView}
        onClose={() => setPremiumFeaturesDrawer(false)}
      />
      <UnderDevFeatureDrawer
        open={underDevFeatureDrawer}
        mobileType={mobileView}
        onClose={() => setUnderDevFeatureDrawer(false)}
      />
    </>
  );
};

export default Account;
