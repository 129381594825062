import { Slide } from '@material-ui/core';
import { makeStyles, useTheme, useMediaQuery } from '@material-ui/core';
import { CloseIcon, WarningIcon } from 'components/Icons';
import AsqButton from 'routes/Subscribe/asq-button';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  confirmContainer: {
    height: 180,
    backgroundColor: theme.common.black,
    position: 'fixed',
    left: '33.333%',
    right: '16.666%',
    bottom: 0,
    borderTopRightRadius: 10,
    borderTopLeftRadius: 10,
    '&.offset': {
      left: 'calc(25% + 176px)',
      right: '25%',
    },
    [theme.breakpoints.down('md')]: {
      '&.offset': {
        left: 'calc(15% + 176px) !important',
      },
      left: '15% !important',
      right: '15% !important',
    },
    [theme.breakpoints.down('sm')]: {
      height: 240,
    },
    [theme.breakpoints.only('xs')]: {
      '&.offset': {
        left: '0 !important',
      },
      left: "0 !important",
      right: "0 !important",
    },
  },
  newPaymentMethodButton: {
    height: 44,
    backgroundColor: 'transparent',
    border: '1px solid white',
    color: 'white',
    '&:hover': {
      color: 'white !important',
      backgroundColor: 'transparent',
    },
    [theme.breakpoints.down('sm')]: {
      width: 233, // Match width of new payment method button
    },
  },
  confirmHeader: {
    textAlign: 'center',
    color: theme.common.white,
    fontFamily: 'Figtree-Bold',
    fontSize: '1.1rem',
    paddingBottom: 10,
  },
  closeIcon: {
    cursor: 'pointer',
    position: 'absolute',
    right: 13,
    top: 10,
    width: 16,
    height: 16,
  },
  errorInnerContainer: {
    height: 'inherit',
  },
  errorImg: {},
}));

const ConfirmSubscriptionSlideout = ({ showSlideout, onConfirm, onClose }) => {
  const classes = useStyles();

  const { breakpoints } = useTheme();
  const smallView = useMediaQuery(breakpoints.down('sm'));

  return (
    <Slide direction='up' in={showSlideout} mountOnEnter unmountOnExit>
      <div className={`${classes.confirmContainer} offset`}>
        <CloseIcon className={classes.closeIcon} onClick={onClose} />
        <Grid
          container
          direction='column'
          alignItems='center'
          justifyContent='center'
          className={classes.errorInnerContainer}
        >
          <Grid item>
            <WarningIcon className={classes.errorImg} />
          </Grid>
          <Grid item>
            <div className={classes.confirmHeader}>How would you like to subscribe?</div>
          </Grid>
          <Grid item container direction={smallView ? 'column' : 'row'} justifyContent='center' alignItems='center' spacing={2}>
            <Grid item>
              <AsqButton
                extraClassName={classes.newPaymentMethodButton}
                isBold
                to='/subscribe'
              >
                New Payment Method
              </AsqButton>
            </Grid>
            <Grid item>
              <AsqButton
                onClick={onConfirm}
                isBold
              >
                Payment Method on File
              </AsqButton>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </Slide>
  );
};

export default ConfirmSubscriptionSlideout;