import { useCallback, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useAlert } from 'react-alert';
import { useFormik } from 'formik';
import { useMutation } from '@tanstack/react-query';
import * as Yup from 'yup';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import InputAdornment from '@material-ui/core/InputAdornment';
import BaseHeader from 'components/Header/BaseHeader';
import { CloseIcon, AttachmentUploadedIcon, AttachmentIcon } from 'components/Icons';
import SubmitSuccess from 'components/SubmitSuccess';
import useUpload from 'hooks/upload';
import { getCopilotAnswer, sendAnswerToAudience } from 'store/creator';
import pencilImg from 'assets/images/pencil.png';
import verifiedBadge from 'assets/images/verified-badge.png';
import copilotHappyImg from 'assets/images/copilot-happy.png';
import matrixGif from 'assets/images/matrix.gif';
import attachmentImg from 'assets/images/attachment.svg';
import attachmentUploadedImg from 'assets/images/attachment-uploaded.svg';
import whiteLogoImg from 'assets/images/white-logo-white-content.svg';
import translateImg from 'assets/images/translate.svg';
import styles from './styles';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles(styles);

const QnAView = ({ questionData, questionTrackingCode }) => {
  const classes = useStyles();
  const alert = useAlert();

  const [answerPromptModalOpen, setAnswerPromptModalOpen] = useState(false);
  const answerPromptRef = useRef({ initialOpened: false, callback: null });

  const [questionFieldDisabled, setQuestionFieldDisabled] = useState(
    !!questionData.first_draft_answer,
  );
  const [answerFieldDisabled, setAnswerFieldDisabled] = useState(!questionData.first_draft_answer);

  const [answerVerified, setAnswerVerified] = useState(false);

  // answer submission api call
  const {
    mutate: onSendAnswerToAudience,
    isLoading: sendAnswerToAudienceIsLoading,
    data: answerToAudience,
  } = useMutation({
    mutationFn: sendAnswerToAudience,
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  // answer form
  const {
    getFieldProps: answerFieldProps,
    touched: aTouched,
    errors: aErrors,
    values: aValues,
    handleChange: aHandleChange,
    handleBlur: aHandleBlur,
    handleSubmit: aHandleSubmit,
  } = useFormik({
    initialValues: {
      answer: questionData.first_draft_answer || '',
      creator_attachment_image_url: '',
      creator_attachment_image_encoded: '',
      creator_attachment_video_url: '',
      creator_attachment_video_encoded: '',
    },
    validationSchema: Yup.object({
      answer: Yup.string()
        .required('Answer is required')
        .min(3, 'Answer must contain at least 3 characters'),
    }),
    onSubmit: (data) => {
      const { answer, creator_attachment_image_url, creator_attachment_video_url } = data;

      const answerHandler = (answer_verified) =>
        onSendAnswerToAudience({
          answer_verified,
          question_tracking_code: questionTrackingCode,
          message: answer,
          image_urls: creator_attachment_image_url ? [creator_attachment_image_url] : [],
          video_urls: creator_attachment_video_url ? [creator_attachment_video_url] : [],
        });

      if (!answerPromptRef.current.initialOpened) {
        answerPromptRef.current.initialOpened = true;
        answerPromptRef.current.callback = answerHandler;
        setAnswerPromptModalOpen(true);
      } else answerHandler(answerVerified);
    },
  });

  const aHandleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      aHandleChange(e);
      aHandleBlur(e);
    },
    [aHandleChange, aHandleBlur],
  );

  // copilot answer api call
  const {
    mutate: onGetCopilotAnswer,
    data: copilotAnswer,
    isLoading: copilotAnswerIsLoading,
  } = useMutation({
    mutationFn: getCopilotAnswer,
    onSuccess: (data) => {
      const { choices: [{ text }] = [{}] } = data || {};

      aHandleChange({
        target: {
          name: 'answer',
          value: text || 'No answer was returned',
        },
      });
      setQuestionFieldDisabled(true);
      setAnswerFieldDisabled(false);
    },
    onError: (err) => {
      alert.error(null, defaultAlertError);
    },
  });

  // image form handling on answer
  const handleFormImageChange = useCallback(
    (formNames) => (formData) => {
      const isVideoUploaded = formData.fileType.split('/')[0] === 'video';
      // image related updates
      aHandleChange({
        target: {
          name: formNames.image_file,
          value: isVideoUploaded ? '' : formData.encodedFile,
        },
      });
      aHandleChange({
        target: {
          name: formNames.image_url,
          value: isVideoUploaded ? '' : formData.fileName,
        },
      });
      // video related updates
      aHandleChange({
        target: {
          name: formNames.video_file,
          value: isVideoUploaded ? formData.encodedFile : '',
        },
      });
      aHandleChange({
        target: {
          name: formNames.video_url,
          value: isVideoUploaded ? formData.fileName : '',
        },
      });
    },
    [],
  );

  // image handling on answer
  const {
    getRootProps: rootAttachmentProps,
    getInputProps: inputAttachmentProps,
    uploadIsLoading: attachmentUploadIsLoading,
  } = useUpload(
    handleFormImageChange({
      image_url: 'creator_attachment_image_url',
      image_file: 'creator_attachment_image_encoded',
      video_url: 'creator_attachment_video_url',
      video_file: 'creator_attachment_video_encoded',
    }),
    {
      disabled: answerFieldDisabled || sendAnswerToAudienceIsLoading,
      videoAccept: true,
    },
  );

  // question form
  const {
    getFieldProps: qustionFieldProps,
    touched: qTouched,
    errors: qErrors,
    handleChange: qHandleChange,
    handleBlur: qHandleBlur,
    handleSubmit: qHandleSubmit,
  } = useFormik({
    initialValues: {
      question: questionData.customer_message,
    },
    validationSchema: Yup.object({
      question: Yup.string()
        .required('Question is required')
        .min(5, 'Question must contain at least 5 characters'),
    }),
    onSubmit: ({ question }) => {
      onGetCopilotAnswer({ questionTrackingCode, prompt: question });
    },
  });

  const qHandleTrimOnBlur = useCallback(
    (e) => {
      e.target.value = e.target.value.trim();
      qHandleChange(e);
      qHandleBlur(e);
    },
    [qHandleChange, qHandleBlur],
  );

  const {
    creator: {
      display_name,
    },
    customer: { name },
    languageMatchedWithCreators,
    header,
    description,
  } = questionData;

  const mismachedLanguageFound =
    languageMatchedWithCreators === false;

  // return true ? (
  //   <SubmitSuccess customerData={{ customer_name: name }} isAudienceQuestionSubmitted />
  return !!answerToAudience ? (
    <SubmitSuccess
      customerData={{ customer_name: name }}
      isAudienceQuestionSubmitted={!!answerToAudience}
    />
  ) : (
    <Grid container className={classes.container}>
      <Grid item sm xs />
      <Grid item sm={6} xs={12}>
        <Grid
          container
          direction='column'
          justifyContent='space-between'
          className={classes.innerContainer}
        >
          <Grid item container direction='column' className={classes.questionSectionContainer}>
            {/* header */}
            <BaseHeader
              className={classes.header}
              leftIcon={<img src={whiteLogoImg} />}
              hideRightIcon
            />
            {/* header logos */}
            <Grid
              item
              container
              className={classes.headerLogosContainer}
              style={{ backgroundImage: `url(${matrixGif})` }}
            >
              <Grid item xs={3} />
              <Grid item xs={6}>
                <Grid container justifyContent='center'>
                  <Grid item>
                    {/* profile logo */}
                    <div
                      className={classes.logoPlaceholderIcon}
                      style={{
                        backgroundImage: `url(${copilotHappyImg})`,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
            {/* creator intro */}
            <Grid item container className={classes.introSectionContainer}>
              <Grid item xs />
              <Grid item xs={10}>
                <Grid container direction='column' spacing={1}>
                  {/* creator info */}
                  <Grid item container direction='column'>
                    <Grid item container justifyContent='center'>
                      <Grid item>
                        <div className={classes.creatorDisplayName}>
                          {display_name || '{Display name}'}'s FirstDraft
                        </div>
                      </Grid>
                    </Grid>
                    <Grid item container justifyContent='center'>
                      <Grid item>
                        <div className={classes.creatorDescription}>
                          Get an AI assist on this question.
                        </div>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* hr */}
                  <Grid item>
                    <hr className={classes.hr} />
                  </Grid>
                  {/* ask text */}
                  <Grid item container justifyContent='center'>
                    <Grid item>
                      <div className={classes.askText}>{name} asks...</div>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
            {/* question textarea */}
            <Grid item className={classes.textareaSectionOutterContainer}>
              <form onSubmit={qHandleSubmit} className={classes.form}>
                <Grid
                  container
                  direction='column'
                  spacing={1}
                  className={classes.textareaSectionInnerContainer}
                >
                  <Grid item className={classes.textAreaContainer}>
                    <TextField
                      classes={{ root: classes.textField }}
                      variant='filled'
                      fullWidth
                      placeholder='Tap to start typing the question...'
                      multiline
                      minRows={3}
                      disabled={questionFieldDisabled}
                      InputProps={{
                        endAdornment: questionFieldDisabled ? (
                          <InputAdornment position='end'>
                            <img
                              className={classes.attachmentIcon}
                              src={pencilImg}
                              alt='attachment'
                              onClick={() => {
                                setQuestionFieldDisabled(false);
                                setAnswerFieldDisabled(true);
                              }}
                            />
                          </InputAdornment>
                        ) : null,
                      }}
                      {...qustionFieldProps('question')}
                      onBlur={qHandleTrimOnBlur}
                      error={!!qTouched.question && !!qErrors.question}
                      helperText={qTouched.question && qErrors.question}
                    />
                  </Grid>
                  {!questionFieldDisabled && (
                    <Fade in>
                      <Grid item container>
                        <Grid item xs />
                        <Grid item xs={10}>
                          <Button
                            type='submit'
                            className={classes.saveBtn}
                            disabled={copilotAnswerIsLoading}
                            fullWidth
                            size='large'
                          >
                            {copilotAnswerIsLoading ? (
                              <Grid container justifyContent='center'>
                                <CircularProgress
                                  className={classes.progress}
                                  thickness={3}
                                  size={31}
                                />
                              </Grid>
                            ) : (
                              <>Get an answer with FirstDraft</>
                            )}
                          </Button>
                        </Grid>
                        <Grid item xs />
                      </Grid>
                    </Fade>
                  )}
                </Grid>
              </form>
            </Grid>
          </Grid>
          {/* answer intro and textarea */}
          <Grid item className={classes.answerSectionOuterContainer}>
            <form onSubmit={aHandleSubmit} className={classes.form}>
              <Grid container direction='column' className={classes.answerSectionInnerContainer}>
                <Grid
                  item
                  container
                  direction='column'
                  spacing={1}
                  className={classes.answerContainer}
                >
                  <Grid item>
                    <div className={classes.answerIntro}>Your FirstDraft says...</div>
                  </Grid>
                  <Grid item className={classes.textAreaContainer}>
                    <TextField
                      classes={{ root: classes.textField }}
                      variant='filled'
                      fullWidth
                      disabled={answerFieldDisabled || sendAnswerToAudienceIsLoading}
                      placeholder='Tap to start typing the answer...'
                      multiline
                      minRows={3}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end' classes={{ root: 'attach' }}>
                            <div
                              style={{ position: 'relative', height: 34 }}
                              {...rootAttachmentProps()}
                            >
                              {aValues.creator_attachment_image_encoded ||
                              aValues.creator_attachment_video_encoded ? (
                                <AttachmentUploadedIcon style={{ cursor: 'pointer' }} />
                              ) : (
                                <AttachmentIcon style={{ cursor: 'pointer' }} />
                              )}
                              <input {...inputAttachmentProps()} />
                              {attachmentUploadIsLoading && (
                                <Backdrop open className={classes.imageBackDrop}>
                                  <CircularProgress color='inherit' thickness={3} size={20} />
                                </Backdrop>
                              )}
                            </div>
                          </InputAdornment>
                        ),
                        endAdornment:
                          (!!copilotAnswer || !!aValues.answer) && !questionFieldDisabled ? (
                            <InputAdornment position='end'>
                              <img
                                onClick={() => {
                                  setQuestionFieldDisabled(true);
                                  setAnswerFieldDisabled(false);
                                }}
                                className={classes.attachmentIcon}
                                src={pencilImg}
                                alt='attachment'
                              />
                            </InputAdornment>
                          ) : null,
                      }}
                      {...answerFieldProps('answer')}
                      onBlur={aHandleTrimOnBlur}
                      error={!!aTouched.answer && !!aErrors.answer}
                      helperText={aTouched.answer && aErrors.answer}
                    />
                  </Grid>
                </Grid>
                {/* submit button */}
                <Grid item container className={classes.footer}>
                  <Grid item xs />
                  <Grid item xs={10}>
                    <Button
                      type='submit'
                      disabled={
                        answerFieldDisabled ||
                        !aValues.answer ||
                        sendAnswerToAudienceIsLoading ||
                        attachmentUploadIsLoading
                      }
                      className={classes.saveBtn}
                      fullWidth
                      size='large'
                    >
                      {sendAnswerToAudienceIsLoading ? (
                        <Grid container justifyContent='center'>
                          <CircularProgress className={classes.progress} thickness={3} size={31} />
                        </Grid>
                      ) : (
                        <>Send to {name}</>
                      )}
                    </Button>
                  </Grid>
                  <Grid item xs />
                </Grid>
              </Grid>
            </form>
          </Grid>
          {/* answer prompt modal */}
          <Fade in={answerPromptModalOpen}>
            <div className={classes.sendAnswerPrompt}>
              <Grid
                container
                direction='column'
                justifyContent='space-between'
                className={classes.sendAnswerPromptContainer}
              >
                {/* close btn */}
                <Grid item>
                  <div className={classes.promptCloseIcon}>
                    <CloseIcon onClick={() => setAnswerPromptModalOpen(false)} />
                  </div>
                </Grid>
                {/* content */}
                <Grid
                  item
                  container
                  direction='column'
                  justifyContent='space-evenly'
                  className={classes.promptContent}
                >
                  {/* expert verified */}
                  <Grid item container direction='column' alignItems='center'>
                    <Grid item>
                      <img src={verifiedBadge} className={classes.verifiedBadgeImg} alt='' />
                    </Grid>
                    <Grid item>
                      <div className={classes.promptHeader}>
                        Expert Verified<sup>TM</sup>
                      </div>
                    </Grid>
                  </Grid>
                  {/* verification content */}
                  <Grid item container>
                    <Grid item xs />
                    <Grid item xs={10}>
                      <Grid
                        container
                        direction='column'
                        className={classes.promptVerificationContainer}
                      >
                        <Grid item className={classes.promptVerificationHeader}>
                          <div className={classes.promptBody}>
                            FirstDraft's responses <span>may contain inaccuracies.</span>
                          </div>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent='center'
                          alignItems='center'
                          className={classes.promptVerificationCheck}
                        >
                          <Grid item>
                            <div className={classes.verificationText}>
                              I have <span>verified</span> this answer.
                            </div>
                          </Grid>
                          <Grid item>
                            <Checkbox
                              checked={answerVerified}
                              onChange={(e) => {
                                setAnswerVerified(e.target.checked);
                              }}
                              checkedIcon={
                                <span className={`${classes.icon} ${classes.checkedIcon}`} />
                              }
                              icon={<span className={classes.icon} />}
                            />
                          </Grid>
                        </Grid>
                        <Grid
                          item
                          container
                          justifyContent='center'
                          className={classes.promptVerificationEdit}
                        >
                          <Grid item>
                            <div
                              onClick={
                                sendAnswerToAudienceIsLoading
                                  ? null
                                  : () => setAnswerPromptModalOpen(false)
                              }
                              className={classes.promptVerificationEditText}
                            >
                              Edit Answer
                            </div>
                          </Grid>
                        </Grid>
                        <Grid item container className={classes.promptVerificationCheck}>
                          <Grid item xs />
                          <Grid item xs={10}>
                            <Button
                              fullWidth
                              onClick={() => answerPromptRef.current.callback(answerVerified)}
                              disabled={sendAnswerToAudienceIsLoading}
                              className={`${classes.promptBtn} ${answerVerified ? '' : 'grey'}`}
                            >
                              {sendAnswerToAudienceIsLoading ? (
                                <Grid container justifyContent='center'>
                                  <CircularProgress
                                    className={classes.promptProgress}
                                    thickness={3}
                                    size={29}
                                  />
                                </Grid>
                              ) : (
                                <>{answerVerified ? 'Send Verified' : 'Send Unverified'}</>
                              )}
                            </Button>
                          </Grid>
                          <Grid item xs />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs />
                  </Grid>

                  {/* translation content */}
                  {mismachedLanguageFound && (
                    <Grid item container>
                      <Grid item xs />
                      <Grid item xs={10}>
                        <Grid
                          container
                          direction='column'
                          className={classes.promptVerificationContainer}
                          alignItems='center'
                        >
                          <Grid item>
                            <img
                              src={translateImg}
                              className={classes.promptTranslationImg}
                              alt=''
                            />
                          </Grid>
                          <Grid item>
                            <div className={classes.drawerTranslationIntro}>
                              {header} <span>Google</span>
                            </div>
                          </Grid>
                          <Grid item>
                            <div className={classes.drawerTranslationText}>{description}</div>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs />
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </div>
          </Fade>
        </Grid>
      </Grid>
      <Grid item sm xs />
    </Grid>
  );
};

export default QnAView;
