import { ButtonBase, Grid, makeStyles, useTheme } from '@material-ui/core';
import { useMutation } from '@tanstack/react-query';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import youtubeLogoRed from 'assets/images/youtube-logo-red.svg';
import yotuubeLogo from 'assets/images/youtube-logo-white.svg';
import { CloseIconAlt } from 'components/Icons';
import { useGoogleLogin } from '@react-oauth/google';
import { enableClips } from 'store/creator';
import { googleAuth } from 'store/auth/google';
import { googleError } from 'utils/common/constants';
import { useAlert } from 'react-alert';
import { defaultAlertError } from 'utils/common/constants';
import { trackAppCtaClick } from 'store/creator';
import { getPathAndPageName } from 'utils/url-utils';
import { useMemo, useState } from 'react';
import { ClipsInfoDrawer } from './ClipsInfoDrawer';

const useStyles = makeStyles((theme) => ({
  topContainer: {
    marginBottom: 10,
    [theme.breakpoints.down('xs')]: {
      marginBottom: 20,
    },
  },
  youtubeLogoDesktop: {
    marginTop: 2,
  },
  title: {
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '21.79px',
  },
  doNotShowAgainButton: {
    fontFamily: 'Figtree-Regular',
    fontSize: 12,
    lineHeight: '16.34px',
    textDecoration: 'underline',
    marginRight: 20,
  },
  closeButton: {
    width: 14,
    height: 14,
  },
  subtitle: {
    marginBottom: 10,
    '& span': {
      fontFamily: 'Figtree-Regular',
      fontSize: 16,
      lineHeight: '19.2px',
    },
  },
  buttonContainer: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  filledButton: {
    height: 44,
    padding: '0 20px',
    borderRadius: 5,
    backgroundColor: '#495EFF',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: 'white',
    width: 152, // Width is hardcoded to match the width of the download buttons in the 'Download Contacts' section on the settings page
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  outlinedButton: {
    height: 44,
    borderRadius: 5,
    padding: '0 20px',
    border: '2px solid #495EFF',
    fontFamily: 'Figtree-Bold',
    fontSize: 16,
    lineHeight: '19.2px',
    color: '#495EFF',
    width: 141, // Width is hardcoded to match the width of the download buttons in the 'Download Contacts' section on the settings page
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  youtubeLogo: {
    marginRight: 10,
  },
}));

const ClipsSignUp = (props) => {
  const {
    onDismiss,
    onDoNotShowAgain,
    onSignUpSuccess,
    showCloseButton,
    showDoNotShowAgainButton,
    hideLogo,
    buttonAlignment = 'flex-start',
  } = props;

  const { breakpoints } = useTheme();
  const classes = useStyles();

  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const alert = useAlert();

  const login = useGoogleLogin({
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/youtubepartner',
    redirect_uri: window.location.origin,
    ux_mode: 'popup',
    onSuccess: (response) => {
      console.log(response);
      onGoogleAuth({ code: response.code, redirect_uri: window.location.origin });
    },
    onError: (err) => {
      if (err.error !== 'access_denied') {
        alert.error(null, { ...defaultAlertError, offsetError: true });
      }
    },
  });

  const { mutate: onGoogleAuth, authLoading } = useMutation({
    mutationFn: googleAuth,
    onSuccess: () => {
      onEnableClips();
    },
    onError: (err) => {
      const errorMessage = err.response.data.message;

      if (errorMessage === googleError.insufficientPermissions) {
        alert.error(null, {
          title: 'Something went wrong',
          body: 'Please give permission in order to connect your account. We never share or sell your data.',
          offsetError: true,
        });
      } else if (errorMessage === googleError.noChannel) {
        alert.error(null, {
          title: 'Something went wrong',
          body: 'We weren’t able to find a YouTube account associated with this email address.',
          offsetError: true,
        });
      } else {
        alert.error(null, {
          title: 'Something went wrong',
          body: 'An unexpected error occurred, please try again. If you continue to have an error please contact us.',
          offsetError: true,
        });
      }

      console.error(err.response?.data);
    },
  });

  const { mutate: onEnableClips, isLoading } = useMutation({
    mutationFn: enableClips,
    onSuccess: () => {
      onSignUpSuccess();
    },
    onError: (err) => {
      console.error(err.response?.data);

      alert.error(null, {
        title: 'Something went wrong',
        body: 'An unexpected error occurred, please try again. If you continue to have an error please contact us.',
        offsetError: true,
      });
    },
  });

  const showTop = useMemo(() => {
    return (!hideLogo && mobileView) || showCloseButton || showDoNotShowAgainButton;
  }, [hideLogo, showCloseButton, showDoNotShowAgainButton, mobileView]);

  const [showInfoDrawer, setShowInfoDrawer] = useState(false);

  const text = "Save time with FirstDraft! Get pre-written, AI generated answers and relevant video clips from your YouTube content. Just review and send."

  return (
    <>
      <ClipsInfoDrawer
        isOpen={showInfoDrawer}
        setIsOpen={setShowInfoDrawer}
        onConnect={() => {
          login();
          setShowInfoDrawer(false);
          trackAppCtaClick({
            ...getPathAndPageName(),
            cta_verbiage: 'Connect Account (Modal)',
          });
        }}
      />
      {showTop && (
        <Grid
          container
          item
          direction='row'
          justifyContent={mobileView ? 'space-between' : 'flex-end'}
          className={classes.topContainer}
        >
          {mobileView && !hideLogo && (
            <Grid item>
              <img src={youtubeLogoRed} alt='Clips Logo' />
            </Grid>
          )}
          {(showCloseButton || showDoNotShowAgainButton) && (
            <Grid item>
              <Grid container item direction='row' alignItems='flex-end'>
                {showDoNotShowAgainButton && (
                  <Grid item>
                    <ButtonBase className={classes.doNotShowAgainButton} onClick={onDoNotShowAgain}>
                      Don't Show Again
                    </ButtonBase>
                  </Grid>
                )}
                {showCloseButton && (
                  <Grid item>
                    <ButtonBase className={classes.closeButton} onClick={onDismiss}>
                      <CloseIconAlt />
                    </ButtonBase>
                  </Grid>
                )}
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
      <Grid container item direction='row' spacing={2}>
        {!mobileView && !hideLogo && (
          <Grid item>
            <img src={youtubeLogoRed} alt='Clips Logo' className={classes.youtubeLogoDesktop} />
          </Grid>
        )}
        <Grid container item sm direction='column'>
            <Grid item className={classes.subtitle}>
              <span>
                {text}
              </span>
            </Grid>
          <Grid item container direction='row' spacing={1} justifyContent={buttonAlignment}>
            <Grid item className={classes.buttonContainer}>
              <ButtonBase
                className={classes.outlinedButton}
                onClick={() => {
                  setShowInfoDrawer(true);
                  trackAppCtaClick({
                    ...getPathAndPageName(),
                    cta_verbiage: 'Learn More',
                  });
                }}
              >
                Learn More
              </ButtonBase>
            </Grid>
            <Grid item className={classes.buttonContainer}>
              <ButtonBase
                className={classes.filledButton}
                onClick={() => {
                  login();
                  trackAppCtaClick({
                    ...getPathAndPageName(),
                    cta_verbiage: 'Connect Account',
                  });
                }}
              >
                <img src={yotuubeLogo} alt='YouTube Logo' className={classes.youtubeLogo} />
                Connect
              </ButtonBase>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default ClipsSignUp;
