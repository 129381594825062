import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import ProceedToTipCheckout from 'routes/Public/Profile/AnswerStep/RevealAnswer/ProceedToTIpCheckout';
import PaymentIntentPendingProcess from 'routes/Public/Profile/QuestionStep/PaymentIntentPendingProcess';
import AnswerAcceptedSuccess from 'components/AnswerAcceptedSuccess';
import { createSubscriptionTipPaymentIntent } from 'store/payment';
import { defaultAlertError } from 'utils/common/constants';

const answerStatusTypes = {
  proceedToTipCheckout: 'proceed-to-tip-checkout',
  checkoutIsProcessing: 'checkout-is-processing',
  successfulPayment: 'successful-payment',
};

const TipFlow = ({ tipSessionData }) => {
  const alert = useAlert();
  const [searchParams, setSearchParams] = useSearchParams();
  const searchClientSecret = searchParams.get('setup_intent_client_secret');
  const searchSessionTrackingCode = searchParams.get('session_tracking_code');

  const [answerStatus, setAnswerStatus] = useState(
    searchClientSecret
      ? answerStatusTypes.checkoutIsProcessing
      : answerStatusTypes.proceedToTipCheckout,
  );
  const [successState, setSuccessState] = useState(null);

  const { mutate: onCreateSubscritpionTipPaymentIntent } = useMutation({
    mutationFn: createSubscriptionTipPaymentIntent,
    onSuccess: ({ amount }) => {
      setSuccessState({ amount, isTip: true });
      setSearchParams(undefined);
      setAnswerStatus(answerStatusTypes.successfulPayment);
    },
    onError: (err) => {
      setSearchParams(undefined);
      setAnswerStatus(answerStatusTypes.proceedToTipCheckout);
      alert.error(null, defaultAlertError);
    },
  });

  return answerStatus === answerStatusTypes.proceedToTipCheckout ? (
    <ProceedToTipCheckout answerData={tipSessionData} />
  ) : answerStatus === answerStatusTypes.checkoutIsProcessing ? (
    <PaymentIntentPendingProcess
      clientSecret={searchClientSecret}
      onSuccess={() =>
        onCreateSubscritpionTipPaymentIntent({ session_tracking_code: searchSessionTrackingCode })
      }
      onError={() => {
        setSearchParams(undefined);
        setAnswerStatus(answerStatusTypes.proceedToTipCheckout);
      }}
    />
  ) : answerStatus === answerStatusTypes.successfulPayment ? (
    <AnswerAcceptedSuccess answerData={tipSessionData} successState={successState} />
  ) : null;
};

export default TipFlow;
