import { useState, useMemo, useEffect, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useAlert } from 'react-alert';
import { useMutation } from '@tanstack/react-query';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { InfoIcon, CloseIcon, TranslateIcon, PencilIcon, CalendarPriorityIcon, CalendarStandardIcon, PaperPlaneIconAlt } from 'components/Icons';
import PaymentForm from '../AnswerStep/PendingPayment/PaymentForm';
import { createPaymentAuthorization } from 'store/payment';
import { triggerPriorityTracking } from 'store/customer';
import styles from './styles';
import { formatPrice } from 'utils/currency-utils';
import { AppDataContext } from 'Context';
import AsqMeCreatorHeader from 'components/Header/AsqMeCreatorHeader';
import PriorityOption from './PriorityOption';
import { defaultAlertError } from 'utils/common/constants';

const useStyles = makeStyles(styles);

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const QuestionPreview = ({
  creatorData,
  customerData,
  questionTrackingCode,
  forcePreviousTemporaryState,
  detectedQuestionOriginLanguage,
  saveIsLoading,
  onSubmitStandardQuestion,
  onStepBack,
}) => {
  const alert = useAlert();
  const classes = useStyles();
  const {
    breakpoints,
    common: { white },
  } = useTheme();

  const { convertPrice } = useContext(AppDataContext);

  const mobileView = useMediaQuery(breakpoints.down('xs'));

  const [drawerOpen, setDrawerOpen] = useState(false);

  const [mode, setMode] = useState('priority');
  const [clientSecret, setClientSecret] = useState('');

  const extractPrice = (
    { price_per_question, price_per_question_custom, currency_code },
    upLiftPrice = false,
  ) => {
    let price;

    if (!isNaN(parseInt(price_per_question))) {
      price = price_per_question;
    } else if (!isNaN(parseInt(price_per_question_custom))) {
      // Custom price is in the creator's currency. Currently, we only support USD so we convert the price.
      price = convertPrice(price_per_question_custom, {
        fromCurrency: currency_code,
        roundUp: true,
      });
    } else {
      return '_';
    }

    price = upLiftPrice ? Math.round(parseInt(price) * 1.5) : price;

    // Audience currency is currenly always USD. Therefore, return price in USD.
    return formatPrice(price, { appendCurrency: true, hideDecimals: true });
  };

  const { mutate: onCreatePaymentAuthorization } = useMutation({
    mutationFn: createPaymentAuthorization,
    onSuccess: (clientSecret) => {
      setClientSecret(clientSecret);
    },
    onError: (err) => {
      setMode('standard');
      alert.error(null, defaultAlertError);
    },
  });

  const { mutate: track } = useMutation({
    mutationFn: triggerPriorityTracking,
  });

  useEffect(() => {
    if (mode === 'priority' && questionTrackingCode && !clientSecret)
      onCreatePaymentAuthorization({ question_tracking_code: questionTrackingCode });
  }, [mode, questionTrackingCode]);

  const stripeProps = useMemo(
    () =>
      clientSecret
        ? {
            options: {
              clientSecret,
              appearance: {
                theme: 'stripe',
                labels: 'floating',
                variables: {
                  colorPrimary: '#495EFF',
                },
                rules: {
                  '.Input': {
                    padding: '7px',
                  },
                },
              },
            },
            stripe: stripePromise,
          }
        : null,
    [clientSecret],
  );

  const { banner_image, logo_image, display_name } = creatorData || {};
  const { languageMatchedWithCreators, header, description } = detectedQuestionOriginLanguage || {};

  const mismachedLanguageFound = languageMatchedWithCreators == false;
  const priorityModeSelected = mode === 'priority';
  const standardModeSelected = mode === 'standard';

  return (
    <Grid
      container
      className={classes.questionPreviewContainer}
      alignContent='flex-start'
      justifyContent='center'
    >
      <Grid item xs={12} className={classes.headerContainer}>
        <AsqMeCreatorHeader title={display_name} iconUrl={logo_image} showLogo={true} />
      </Grid>
      <Grid container direction='column' className={classes.previewContainer}>
        <Grid item container direction='column' className={classes.dataSectionContainer}>
          {/* preview content */}
          <Grid item container direction='column' className={classes.previewContentContainer}>
            <Grid
              container
              item
              justifyContent='space-between'
              className={classes.questionHeaderContainer}
              alignContent='center'
            >
              <Grid item>
                <span className={classes.questionHeader}>Your Question:</span>
              </Grid>
              <Grid item>
                <Button onClick={onStepBack} classes={{ root: classes.editPencilButton }}>
                  <PencilIcon />
                </Button>
              </Grid>
            </Grid>
            <Grid item className={classes.questionContentContainer}>
              <p className={classes.customerMessage}>{customerData.customer_message}</p>
            </Grid>
            {(customerData.customer_attachment_image_encoded ||
              customerData.customer_attachment_video_encoded) && (
              <Grid item container justifyContent='center' className={classes.attachmentContainer}>
                <Grid item>
                  {customerData.customer_attachment_video_encoded ? (
                    <video className={classes.customerAttachment} controls>
                      <source
                        src={customerData.customer_attachment_video_encoded}
                        type='video/mp4'
                      />
                    </video>
                  ) : (
                    <img
                      src={customerData.customer_attachment_image_encoded}
                      className={classes.customerAttachment}
                      alt=''
                    />
                  )}
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* translated language display */}
          {mismachedLanguageFound && (
            <Grid
              container
              justifyContent='space-between'
              alignItems='center'
              className={classes.mismatchContainer}
            >
              <Grid item>
                <Grid container alignItems='center'>
                  <Grid item>
                    <TranslateIcon height={30} width={30} />
                  </Grid>
                  <Grid item>
                    <div className={classes.translationIntro}>
                      {header} <span>Google</span>
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item>
                <InfoIcon
                  fillColor={white}
                  onClick={() => setDrawerOpen(true)}
                  className={classes.infoSvg}
                />
              </Grid>
            </Grid>
          )}
          {/* standard/priority options  (not applicable for $0 & tip ) */}
          <Grid
            item
            container
            direction='column'
            alignItems='flex-start'
            className={classes.prioritySectionContainer}
          >
            <Grid item className={classes.headerTextContainer}>
              <span>How fast do you need an answer?</span>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <PriorityOption
                  Icon={CalendarPriorityIcon}
                  title='Priority'
                  subtitle='Within 3 Hours'
                  price={extractPrice(creatorData, true)}
                  onClick={() => {
                    track({ question_tracking_code: questionTrackingCode });
                    alert.error(
                      <>Please select <strong>STANDARD</strong> and get an answer in 24 hours or less.</>, 
                      { title: 'This feature is coming soon', }
                    );
                  }}
                />
              </Grid>
              <Grid item xs={6}>
                <PriorityOption
                  Icon={CalendarStandardIcon}
                  title='Standard'
                  subtitle='Within 24 Hours'
                  price={extractPrice(creatorData)}
                  isSelected={true}
                  onClick={() => setMode('priority')}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* payment section (applicable for priority option) */}
          {priorityModeSelected && (
            <Grid item container>
              <Grid
                item
                xs={12}
                className={`${classes.headerTextContainer} ${classes.paymentMethodHeader}`}
              >
                <span>Payment Method</span>
              </Grid>
              <Grid item xs={12} className={classes.paymentMethodInfo}>
                <p>
                  Your card will only be charged after receiving your answer. Two follow ups are
                  included.
                </p>
              </Grid>
              <Grid item xs={12} className={classes.paymentInnerSection}>
                {stripeProps && (
                  <Elements {...stripeProps}>
                    <PaymentForm
                      classes={classes}
                      redirectUrl={`${window.location.href}?question_tracking_code=${questionTrackingCode}`}
                      textPrompt={null}
                      useApplePay={true}
                      useGooglePay={true}
                      submitText={
                        <div className={classes.saveBtnLabel}>
                          <span><PaperPlaneIconAlt fillColor='white' /></span>
                          <span>Send Question</span>
                        </div>
                      }
                      error={forcePreviousTemporaryState}
                    />
                  </Elements>
                )}
              </Grid>
            </Grid>
          )}
          {standardModeSelected && (
            <>
              {/* informative text for priority prompt */}
              <Grid item container direction='column' alignItems='center' spacing={2}>
                <Grid item>
                  <div className={`${classes.propmptToPriorityText} firstChild`}>
                    <span className='bold'>Seems like an important question!</span> <br />
                    Are you sure you don't need an answer fast?
                  </div>
                </Grid>
                <Grid item>
                  <div className={classes.propmptToPriorityText}>
                    <span className='blue'>For a fast answer, choose Priority instead.</span>
                    <br />
                    Priority questions are answered quickly and your satisfaction is guaranteed.
                  </div>
                </Grid>
              </Grid>

              {/* submit button (applicable for standard option & $0/tip) */}
              <Grid item container className={classes.customerFooterContainer}>
                <Grid item xs />
                <Grid item xs={10}>
                  <Button
                    onClick={() => onSubmitStandardQuestion()}
                    disabled={saveIsLoading}
                    type='submit'
                    className={classes.saveBtn}
                    fullWidth
                    size='large'
                  >
                    {saveIsLoading ? (
                      <Grid container justifyContent='center'>
                        <CircularProgress className={classes.progress} thickness={3} size={34} />
                      </Grid>
                    ) : (
                      <Grid container justifyContent='center' spacing={3}>
                        <Grid item>
                          <PaperPlaneIconAlt className={classes.saveBtnIcon} />
                        </Grid>
                        <Grid item>
                          <span>Send Question</span>
                        </Grid>
                      </Grid>
                    )}
                  </Button>
                </Grid>
                <Grid item xs />
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      {/* translation drawer */}
      <Drawer
        classes={{ root: classes.drawer, paperAnchorTop: classes.paper }}
        transitionDuration={400}
        anchor='top'
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
      >
        <Grid container className={classes.drawerSkeletonContainer}>
          <Grid item sm xs />
          <Grid item sm={6} xs={12}>
            <Grid container direction='column' className={classes.drawerContainer}>
              {/* x icon */}
              <Grid item container justifyContent='flex-end' className={classes.closeIconContainer}>
                <Grid item>
                  <CloseIcon onClick={() => setDrawerOpen(false)} />
                </Grid>
              </Grid>
              {/* translate icon */}
              <Grid
                item
                container
                justifyContent='center'
                className={classes.translationIconContainer}
              >
                <Grid item>
                  <TranslateIcon height={70} width={70} />
                </Grid>
              </Grid>
              {/* translated intro */}
              <Grid
                item
                container
                justifyContent='center'
                className={classes.translationIntroContainer}
              >
                <Grid item>
                  <div className={classes.drawerTranslationIntro}>
                    {header} <span>Google</span>
                  </div>
                </Grid>
              </Grid>
              {/* translated body */}
              <Grid
                item
                container
                justifyContent='center'
                className={classes.translationIntroContainer}
              >
                <Grid item>
                  <div className={classes.drawerTranslationText}>{description}</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm xs />
        </Grid>
      </Drawer>
    </Grid>
  );
};

export default QuestionPreview;
