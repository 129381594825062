import { useCallback, useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getPaymentMethodDetails } from 'store/payment';
import { useAlert } from 'react-alert';

const PaymentForm = ({
  classes,
  error: propError,
  children,
  redirectUrl,
  useSetupIntent = false, // Set this to true if you'd like to confirm a SetupIntent instead of a PaymentIntent
  useGooglePay,
  useApplePay,
  onSetupSuccess,
  fetchPaymentDetailsOnSuccess, // fetch payment details (last 4 digits) after successful setup, must be used along with onSetupSuccess and useSetupIntent
  paymentInformationHeader,
  paymentInformationFooter,
  textPrompt = 'Payment information',
  submitText,
  footerButton = null,
  footerGrid = 12,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const alert = useAlert();
  const [searchParams] = useSearchParams();

  const [transactionIsLoading, setTransactionIsLoading] = useState(false);
  const [transactionIsProcessing, setTransactionIsProcessing] = useState(false);
  const [formIsReady, setFormIsReady] = useState(false);
  const [formValidationPassed, setFormValidationPassed] = useState(false);

  useEffect(() => {
    if (propError) {
      alert.error(null, { title: propError, body: 'Update your card information and try again.', });
    }
  }, [propError])

  useEffect(() => {
    if (!stripe) return;

    const clientSecretParam = useSetupIntent ? 'setup_intent_client_secret' : 'payment_intent_client_secret';

    const clientSecret = searchParams.get(clientSecretParam);
    if (!clientSecret) return;

    if (useSetupIntent) {
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
        switch (setupIntent.status) {
          case 'succeeded':
            window.location.reload();
            break;
  
          case 'processing': {
            setTransactionIsProcessing(true);
            console.log("Setup processing. We'll update you when setup is set.");
            break;
          }
  
          case 'requires_payment_method': {
            setTransactionIsProcessing(false);
            console.log('Setup failed. Update your card information and try again.');
            alert.error(null, { title: 'Card setup failed', body: 'Update your card information and try again.', });
            break;
          }
  
          default: {
            setTransactionIsProcessing(false);
            alert.error(null, { title: 'Something went wrong', body: 'Update your card information and try again.', });
            break;
          }
        }
      });
    } else {
      stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case 'succeeded':
          case 'requires_capture':
            window.location.reload();
            break;
  
          case 'processing': {
            setTransactionIsProcessing(true);
            console.log("Payment processing. We'll update you when payemnt is set.");
            break;
          }
  
          case 'requires_payment_method': {
            setTransactionIsProcessing(false);
            console.log('Payment failed. Update your card information and try again.');
            alert.error(null, { title: 'Card setup failed', body: 'Update your card information and try again.', });
            break;
          }
  
          default: {
            setTransactionIsProcessing(false);
            alert.error(null, { title: 'Something went wrong', body: 'Update your card information and try again.', });
            break;
          }
        }
      });
    }
  }, [stripe]);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();

      if (!stripe || !elements) return;

      setTransactionIsLoading(true);

      // validation error
      const { error: submitError } = await elements.submit();
      if (submitError) {
        setTransactionIsLoading(false);
        alert.error(null, { title: submitError?.message || submitError, body: 'Update your card information and try again.', });
        return;
      }

      const stripeOptions = {
        elements,
        confirmParams: {
          return_url: redirectUrl || window.location.href,
        },
        redirect: onSetupSuccess && useSetupIntent ? 'if_required' : 'always',
      }

      let result;
      if (useSetupIntent) {
        result = await stripe.confirmSetup(stripeOptions);
      } else {
        result = await stripe.confirmPayment(stripeOptions);
      }

      
      if (!result.error) {
        if (onSetupSuccess && result.setupIntent) {
          let paymentDetails;
  
          if (fetchPaymentDetailsOnSuccess) {
            paymentDetails = await getPaymentMethodDetails(result.setupIntent.payment_method);
          }
  
          onSetupSuccess(paymentDetails);
        }
      } else {
        alert.error(null, { title: result.error?.message || result.error, body: 'Update your card information and try again.', });
      }

      setTransactionIsLoading(false);
    },
    [stripe, elements],
  );

  return (
    <form onSubmit={handleSubmit} className={classes.form}>
      <Grid
        container
        direction='column'
        justifyContent='space-between'
        className={classes.formContainer}
      >
        {transactionIsProcessing ? (
          <Grid
            item
            container
            direction='column'
            justifyContent='center'
            alignItems='center'
            spacing={2}
          >
            <Grid item className={classes.paymentSetupProcessing}>
              <div>Payment setup is processing</div>
            </Grid>
            <Grid item>
              <CircularProgress size={40} />
            </Grid>
          </Grid>
        ) : (
          <>
            {/* loader */}
            {!formIsReady && (
              <Grid item container justifyContent='center'>
                <Grid item>
                  <CircularProgress size={40} />
                </Grid>
              </Grid>
            )}

            <Grid item container>
              {/* header */}
              {formIsReady && !!paymentInformationHeader && (
                <Grid item xs={12}>
                  {paymentInformationHeader}
                </Grid>
              )}
              {formIsReady && (
                <Grid item xs={12}>
                  <div className={classes.revealTextPrompt}>{textPrompt}</div>
                </Grid>
              )}
              {/* Stripe element */}
              <Grid item xs={12}>
                <PaymentElement
                  options={{
                    terms: {
                      applePay: useApplePay ? 'auto' : 'never',
                      bancontact: 'never',
                      card: 'never',
                      cashapp: 'never',
                      googlePay: useGooglePay ? 'auto' : 'never',
                      ideal: 'never',
                      paypal: 'never',
                      sepaDebit: 'never',
                      sofort: 'never',
                      usBankAccount: 'never',
                    },
                    paymentMethodOrder: ['apple_pay', 'google_pay', 'card',],
                  }}
                  onChange={(e) => {
                    setFormValidationPassed(e.complete);
                  }}
                  onReady={() => {
                    setFormIsReady(true);
                  }}
                />
              </Grid>

              {/* below form section */}
              {formIsReady && !!paymentInformationFooter && (
                <Grid item xs={12}>
                  {paymentInformationFooter}
                </Grid>
              )}
            </Grid>
            {children}
            {/* footer */}
            <Grid item container className={classes.customerFooter}>
              <Grid item xs />
              <Grid item xs={footerGrid}>
                <Grid container direction='column'>
                  <Grid item>
                    <Button
                      disabled={
                        !stripe ||
                        !elements ||
                        !formIsReady ||
                        !formValidationPassed ||
                        transactionIsLoading
                      }
                      type='submit'
                      className={classes.saveBtn}
                      fullWidth
                      size='large'
                    >
                      {transactionIsLoading ? (
                        <CircularProgress color='inherit' size={31} />
                      ) : (
                        submitText || 'Continue to Answer'
                      )}
                    </Button>
                  </Grid>
                  {footerButton}
                </Grid>
              </Grid>
              <Grid item xs />
            </Grid>
          </>
        )}
      </Grid>
    </form>
  );
};

export default PaymentForm;
